import React from "react";
import { CircularProgress } from "@mui/material";
export default function Loader() {
  return (
    <>
      <CircularProgress
        style={{
          textAlign: "center",
          display: "block",
          margin: "20% auto",
        }}
      />
    </>
  );
}
