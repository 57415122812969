import { MailOutline, PhoneOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import styles from "../../css/CommonComponent/Footer.module.css";

const Footer = () => {
  return (
    <section className={styles.footerSection}>
      <div className={styles.first} style={{ gap: "20px" }}>
        <Link
          to="/security-privacy"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          Privacy & Security
        </Link>
      </div>
      <div className={styles.second}>
        <div>
          <MailOutline />
          <a
            href="mailto:help@eqlfin.com"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <span>help@eqlfin.com </span>
          </a>
        </div>
        {/* <div>
          <PhoneOutlined />{" "}
          <a
            href="tel:8076438688"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <span>+91-8076438688</span>
          </a>
        </div> */}
      </div>
      <div className={styles.third}>
        Growthfile Analytics Private Limited © {new Date().getFullYear()}
      </div>
    </section>
  );
};

export default Footer;
