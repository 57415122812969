import { TextField } from "@mui/material";
import React from "react";
import styles from "../../css/Common/FormFields.module.css";

const PhoneNumberField = ({
  phoneNumber,
  phoneError,
  handlePhoneChange,
  disabled,
  required = true,
  label = "Phone Number",
  sx,
}) => {
  return (
    <TextField
      className={styles.formField}
      inputProps={{
        maxLength: 10,
      }}
      sx={{ background: "transparent !important", maxWidth: "300px", ...sx }}
      label={label}
      required={required}
      onChange={handlePhoneChange}
      value={phoneNumber}
      error={phoneError}
      helperText={phoneError && "Enter a valid phone number"}
    />
  );
};

export default PhoneNumberField;
