import React, { useContext, useState, useEffect, useRef } from "react";
import { trackAuth } from "../firebaseSetupTrack";
import { updateProfile, updateEmail } from "firebase/auth";

const TrackContext = React.createContext();

export function useTrackAuth() {
  return useContext(TrackContext);
}
export default function TrackProvider({ children }) {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signupStatus, setSignUpStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [customClaim, setCustomClaim] = useState(null);

  const authToken = useRef(null);

  useEffect(() => {
    const unsubscribe = trackAuth.onAuthStateChanged(async (user) => {
      if (user) {
        const tokens = await user.getIdToken();
        authToken.current = tokens;
        setToken(tokens);
        setCurrentUser(user);
        setSignUpStatus(true);
      } else {
        setSignUpStatus(false);
        setToken("");
        setCurrentUser(null);
        setLoading(false);
        setCustomClaim(null);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    signupStatus,
    loading,
    token,
    currentUser,
    customClaim,
    updateProfile,
    updateEmail,
    authToken,
  };
  return (
    <TrackContext.Provider value={value}>{children}</TrackContext.Provider>
  );
}
