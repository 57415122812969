import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const trackApp = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_API_KEY_TRACK,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_TRACK,
    projectId: process.env.REACT_APP_PROJECTID_TRACK,
    storageBucket: process.env.REACT_FIREBASE_STORAGEBUCKET_TRACK,
    messagingSenderId: process.env.REACT_FIREBASE_MESSAGINGSENDERID_TRACK,
    appId: process.env.REACT_FIREBASE_APPID_TRACK,
    measurementId: process.env.REACT_FIREBASE_MEASUREMENTID_TRACK,
  },
  "trackAuth"
);

export const trackAuth = trackApp.auth();

export const uiConfig = {
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: "image",
        size: "invisible",
        badge: "bottomright",
      },
      defaultCountry: "IN",
    },
  ],
  signInFlow: "popup",
};
export default trackApp;
