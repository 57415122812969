import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./Contexts/AuthContext";
import Home from "./components/Home/Home";
import NonExistRoute from "./components/NonExistRoute";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import Security from "./components/website/Security";

import Login from "./components/webApp/Login";

import PayLater from "./components/webApp/PayLater";
import { appTheme } from "./themes/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import DemoVideoPlay from "./components/DemoVideo/DemoVideoPlay";
// import DemoVideo from "./components/DemoVideo/DemoVideo";
import TrackProvider from "./Contexts/TrackContext";
import AboutUs from "./components/AboutUs";
import SupportPage from "./components/SupportPage/SupportPage";
import { DemoVideoProvider } from "./Contexts/DemoVideoContext";
import ErrorPage from "./components/Homepage/ErrorPage";

import BalanceConfirmation from "./components/ProductPage/BalanceConfirmation";
import MobileLogin from "./components/mobileApp/MobileLogin";

import BalanceConfirm from "./components/BalanceConfirmation/BalanceConfirm";
import Invoice from "./components/BalanceConfirmation/Invoice";
import Declaration from "./components/BalanceConfirmation/Declaration";

import DeleteAccount from "./components/DeleteAccount/DeleteAccount";
import PriorityPayment from "./components/ProductPage/PriorityPayment";
import RewardBenefits from "./components/ProductPage/RewardBenifits";
import PersonalAssistant from "./components/ProductPage/PersonalAssistant";
import Loader from "./components/Common/Loader";
import PurchaseSuccess from "./components/PurchaseSuccess";

const Campaign = React.lazy(() =>
  import("./components/DemoVideo/Campaign/Campaign")
);
const FirstPlan = React.lazy(() =>
  import("./components/Earlypayment/FirstPlan")
);

const TRACKING_ID = "UA-232391990-2";
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <TrackProvider>
      <AuthProvider>
        <DemoVideoProvider>
          <BrowserRouter>
            <ThemeProvider theme={appTheme}>
              <CssBaseline enableColorScheme />
              <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/security-privacy" element={<Security />} />

                <Route path="/login" element={<Login />} />

                <Route path="/paylater" element={<PayLater />} />
                <Route path="/about" element={<AboutUs />} />
                <Route
                  path="/services/reward-benefits"
                  element={<RewardBenefits />}
                />
                <Route path="/support-page" element={<SupportPage />} />
                <Route path="/office-error" element={<ErrorPage />} />
                <Route path="/campaign" element={<Campaign />} />

                <Route
                  path="/services/balance-confirmation"
                  element={<BalanceConfirmation />}
                />
                <Route
                  path="/services/personal-assistant"
                  element={<PersonalAssistant />}
                />
                <Route
                  path="/services/priority-payments"
                  element={<PriorityPayment />}
                />
                <Route
                  path="/balance-confirmation"
                  element={<BalanceConfirm />}
                />
                <Route
                  path="/balance-confirmation/invoice"
                  element={<Invoice />}
                />
                <Route
                  path="/balance-confirmation/declare"
                  element={<Declaration />}
                />
                <Route
                  path="/plan-buy"
                  element={
                    <React.Suspense fallback={Loader}>
                      <FirstPlan />
                    </React.Suspense>
                  }
                />

                <Route path="/mobileapp/login" element={<MobileLogin />} />

                <Route path="*" element={<NonExistRoute />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/purchase-success" element={<PurchaseSuccess />} />
              </Routes>
            </ThemeProvider>
          </BrowserRouter>
        </DemoVideoProvider>
      </AuthProvider>
    </TrackProvider>
  );
}

export default App;
