import React, { useState, useEffect, useRef } from "react";

export default function TypeWriter({ text, delay, infinite }) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const textRef = useRef("");

  useEffect(() => {
    let timeout;

    if (currentIndex <= text.length) {
      
      timeout = setTimeout(() => {
        setCurrentText((prevText) =>
          text[currentIndex] !== undefined
            ? prevText + text[currentIndex]
            : prevText
        );
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      // ADD THIS CHECK
      //   setCurrentIndex(0);
      //   setCurrentText("");
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return (
    <>
      {currentIndex === 55 ? (
        <>
          <span style={{ fontWeight: "bold" }}>{currentText}</span> <br />
        </>
      ) : (
        <span style={{ fontWeight: "bold" }}>{currentText}</span>
      )}
    </>
  );
}
