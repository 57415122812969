import { useState, createContext } from "react";
const DemoVideoContext = createContext();

export const DemoVideoProvider = (props) => {
  const [currentComponentState, setCurrentComponentState] = useState("form");
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(20);
  const [phone, setPhone] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  return (
    <DemoVideoContext.Provider
      value={{
        currentComponentState,
        setCurrentComponentState,
        loading,
        setLoading,
        seconds,
        setSeconds,
        phone,
        setPhone,
        otpLoading,
        setOtpLoading,
      }}
    >
      {props.children}
    </DemoVideoContext.Provider>
  );
};

export default DemoVideoContext;
