import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../css/balanceConfirmation/BalanceInvoice.module.css";
import arrowleft from "../../images/commons/arrowleft.svg";
import calendarIcon from "../../images/commons/calendaricon.svg";
import moment from "moment";
import EarlyPaymentTemplate from "../Earlypayment/EarlyPaymentTemplate";
import { invoiceAction } from "../webApp/helper";

export default function Invoice(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const invoice = location?.state;
  console.log(invoice);

  useEffect(() => {});
  return (
    <EarlyPaymentTemplate>
      <div className={styles.summaryView}>
        <div className={styles.backSection}>
          <img
            src={arrowleft}
            alt="left arrow"
            className={styles.leftArrow}
            onClick={() => navigate("/balance-confirmation")}
          />
          {invoice !== null ? invoice.attachment.name.value : ""}
        </div>

        <br />

        <div className={styles.rowSeparated}>
          <div className={styles.rowHeading}>Invoice Details</div>
        </div>

        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>Due Date</div>
          <div className={styles.iconContainer}>
            <img src={calendarIcon} alt="calendar icon" width={"18px"} />
            {invoice !== null ? moment(invoice.Logs.dueDateTimestamp).format("DD-MM-YYYY") : ""}
          </div>
        </div>

        <div className={styles.rowSeparated}>
          <div>Taxable Amount (₹)</div>
          <div>
            {/* 78987 */}
            {invoice !== null
              ? (
                  invoice.attachment.totalAmount.value -
                  (invoice.attachment.cgst.value +
                    invoice.attachment.sgst.value +
                    invoice.attachment.igst.value)
                ).toLocaleString("en-IN")
              : ""}
          </div>
        </div>

        <div className={styles.rowSeparated}>
          <div>
            {invoice !== null
              ? invoice.attachment.igst.value > 0
                ? "IGST (₹)"
                : "CGST + SGST (₹)"
              : ""}
          </div>

          <div>
            {" "}
            {invoice !== null
              ? invoice.attachment.igst.value > 0
                ? invoice.attachment.igst.value.toLocaleString("en-IN")
                : (
                    invoice.attachment.cgst.value +
                    invoice.attachment.sgst.value
                  ).toLocaleString("en-IN")
              : ""}
          </div>
        </div>

        <div className={styles.rowSeparated}>
          <div>Total Amount (₹)</div>
          <div>
            {invoice !== null
              ? invoice.attachment.totalAmount.value.toLocaleString("en-IN")
              : ""}
          </div>
        </div>

        <div className={styles.rowSeparated}>
          <div>TDS (₹)</div>
          <div>
            {invoice !== null
              ? invoice.attachment.tds.value.toLocaleString("en-IN")
              : ""}
          </div>
        </div>
        <div className={styles.rowSeparated}>
          <div>Paid Amount (₹)</div>
          <div>
            {invoice !== null
              ? invoice.Logs.settledAmount.toLocaleString("en-IN")
              : ""}
          </div>
        </div>

        <br />

        <div className={styles.rowSeparated}>
          <div className={styles.rowHeading}>Invoice Details</div>
        </div>

        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>Invoice Date</div>
          <div className={styles.iconContainer}>
            <img src={calendarIcon} alt="calendar icon" width={"18px"} />
            {invoice !== null
              ? moment(invoice.schedule[0].endTime).format("DD-MM-YYYY")
              : ""}
          </div>
        </div>

        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>Invoice Number</div>
          <div className={styles.iconContainer}>
            {invoice !== null ? invoice.attachment.name.value : ""}
          </div>
        </div>

        <br />

        <div className={styles.rowSeparated}>
          <div className={styles.rowHeading}>Supplier Details</div>
        </div>

        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>GSTIN</div>
          <div className={styles.details}>
            {invoice !== null ? invoice.attachment.billingBranch.value : ""}
          </div>
        </div>
        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>Address</div>
          <div className={styles.details}>
            {invoice !== null ? invoice.Logs.sellerOffice : ""}
          </div>
        </div>

        <br />

        <div className={styles.rowSeparated}>
          <div className={styles.rowHeading}>Client Details</div>
        </div>
        <div className={styles.rowLeft}>
          <div className={styles.fixedWithContainer}>Client Name</div>
          <div className={styles.details}>
            {invoice !== null ? invoice.attachment.billingCustomer.value : ""}
          </div>
        </div>
      </div>
    </EarlyPaymentTemplate>
  );
}
