import React from "react";
import styles from "../../css/thankyou.module.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, [3000]);
  }, []);
  return (
    <>
      <div className={styles.addPeopleHeaderContainer}>
        <div className={styles.addPeopleHeader}>Error!</div>
        <div className={styles.addPeopleSubHeader}>Office Does not exit.</div>
        <div className={styles.addPeopleSubHeader}>
          Redirecting you to homepage
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
