import {
  apiHeader,
  apiHeaderMs,
  instantUrl,
  leadCaptureUrl,
  url,
} from "../config";
import moment from "moment";
export const regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^[6-9][0-9]{9}$/;

export const acquisitonApiCall = async (body, token) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
    redirect: "follow",
  };

  const response = await fetch(`${url}/profile/acquisition`, requestOptions);
  const data = await response.json();
  return data;
};

export const gstVerification = (gstNumber) => {
  const gstRegex =
    /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
  const result = gstRegex.test(gstNumber);
  return result;
};

export const gstDataFetch = async (gstNumber, token) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Firebase-Env", apiHeaderMs);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      // `https://api.eqlfin.com/gstlookup?gstNumber=${gstNumber}`,
      `${url}/gstlookup?gstNumber=${gstNumber}`,
      requestOptions
    );
    const data = await response.json();
    if (data.success) {
      return {
        status: true,
        message: "",
        data: data.data,
      };
    } else {
      const timestamp = Date.now();
      const body = {
        messageBody: {
          phoneNumber: "",
          gstNumber: gstNumber,
          timestamp: Date.now(),
          date: new Date(timestamp).toLocaleString(),
        },
        subject: data.message,
      };
      const requestOption = {
        method: "POST",
        //TODO: Check headers here
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(body),
        redirect: "follow",
      };
      fetch(`${instantUrl}/growthfile/instant`, requestOption)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result.status);
          // console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
      return {
        status: false,
        message: "Error in Gst Data Fetch",
        data: data.fetchedData,
      };
    }
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};

export const gstDataFetchLead = async (details) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${details.token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      // `https://api.eqlfin.com/gstlookup?gstNumber=${gstNumber}`,
      `${leadCaptureUrl}/gstlookup?gstNumber=${details.gstNumber}&phoneNumber=${details.phoneNumber}&source=${details.source}`,
      requestOptions
    );
    const data = await response.json();
    if (data.success) {
      return {
        status: true,
        message: "",
        data: data.data,
      };
    } else {
      const timestamp = Date.now();
      const body = {
        messageBody: {
          phoneNumber: "",
          gstNumber: details.gstNumber,
          timestamp: Date.now(),
          date: new Date(timestamp).toLocaleString(),
        },
        subject: data.message,
      };
      const requestOption = {
        method: "POST",
        //TODO: Check headers here
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(body),
        redirect: "follow",
      };
      fetch(`${instantUrl}/growthfile/instant`, requestOption)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result.status);
          // console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
      return {
        status: false,
        message: "Error in Gst Data Fetch",
        data: data.fetchedData,
      };
    }
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};

export const addAdminRequest = async (
  adminDetails,
  token,
  office,
  officeId
) => {
  adminDetails.shift();

  const requests = await adminDetails.map(async (admin) => {
    if (admin.name === "" && admin.email === "" && admin.phoneNumber === "") {
      return "true";
    } else {
      const body = {
        geopoint: {
          latitude: 0,
          longitude: 0,
        },
        template: "admin",
        share: [],
        products: [],
        attachment: {
          Email: {
            type: "string",
            value: admin.email,
          },
          Name: {
            type: "string",
            value: admin.name,
          },
          "Phone Number": {
            type: "phoneNumber",
            value: `+91${admin.phoneNumber}`,
          },
        },
        report: "role",
        schedule: [],
        venue: [],
        timestamp: Date.now(),
        office: office,
        officeId: officeId,
      };
      const requestOption = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Firebase-Env": apiHeader,
        },
        body: JSON.stringify(body),
        redirect: "follow",
      };
      return fetch(`${url}/activities/create`, requestOption).then((res) =>
        res.text()
      );
    }
  });

  let results = false;

  if (requests === "true") {
    return true;
  } else {
    await Promise.all(requests).then((allResults) => {
      // console.log(typeof allResults);
      const res = allResults;

      // console.log(res.json());
      if (res.keys.length === 0) {
        results = true;
      } else {
        res.success ? (results = true) : (results = false);
      }
    });

    return results;
  }

  // console.log({ promiseArray });

  // await Promise.all(adminDetails.forEach( async item => {
  //   const res = await fetch(`${url}/activities/create`, requestOption)
  // }))

  // let response = false;
  // fetch(`${url}/api/activities/create`)
  //   .then((response) => response.text())
  //   .then((result) => {
  //     console.log(result);
  //     response = JSON.parse(result);
  //   })
  //   .catch((error) => {
  //     response = error;
  //     console.log(error);
  //   });
  // return;
};

export const setWhatsappOptIn = async (token, phone, status) => {
  const body = {
    phoneNumber: `+91${phone}`,
    checked: status ? true : false,
  };

  const resp = await fetch(`${url}/whatsappoptin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeaderMs,
    },
    body: JSON.stringify(body),
    redirect: "follow",
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log("whatsapp opt in: ", data);
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  return resp;
};

export const addAdmin = async (admin) => {
  const { name, email, phoneNumber, token, officeId, office } = admin;
  const body = {
    geopoint: {
      latitude: 0,
      longitude: 0,
    },
    template: "admin",
    share: [],
    products: [],
    attachment: {
      email: {
        type: "string",
        value: email,
      },
      adminName: {
        type: "string",
        value: name,
      },
      phoneNumber: {
        type: "phoneNumber",
        value: `+91${phoneNumber}`,
      },
    },
    report: "role",
    schedule: [],
    venue: [],
    timestamp: Date.now(),
    office: office,
    officeId: officeId,
  };
  const requestOption = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
    redirect: "follow",
  };
  const resp = await fetch(`${url}/activities/create`, requestOption);
  const data = await resp.json();
  return data;
};

export const createOffice = async (
  token,
  gstNumber,
  name,
  email,
  phoneNumber
) => {
  const body = {
    companyLogo: "",
    timezone: "Asia/Kolkata",
    gst: gstNumber,
    yearOfEstablishment: 2022,
    timestamp: Date.now(),
    firstContact: {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
    },
    geopoint: null,
  };

  const resp = await fetch(`${url}/services/office`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
  });
  const data = await resp.json();

  return data;
};

export const createCustomer = async (token, customerGstNumber, officeId) => {
  const body = {
    gstNumber: customerGstNumber,
    officeId: officeId,
  };

  const resp = await fetch(`${url}/createcustomer`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeaderMs,
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();

  return data;
};

export const planBuyRequest = async (body, token) => {
  const resp = await fetch(`${url}/advance-pack/create-purchase-request`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();

  return data;
};
export const planBuyRequestLead = async (body, token) => {
  const resp = await fetch(`${leadCaptureUrl}/planbuy`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();
  return data;
};
export const balanceRequest = async (token, officeId) => {
  //

  var raw = undefined;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeader);
  var requestOptions = {
    method: "GET",
    body: raw,
    headers: myHeaders,
    redirect: "follow",
  };

  const result = await fetch(
    `${url}/office/${officeId}/officeBalances`,
    requestOptions
  );
  let res = await result.json();
  return res;
};
export const earlyPaymentRequest = async (token, body) => {
  const resp = await fetch(`${url}/early-payment/create`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();

  return data;
};

export const sendUserInfo = async (token, body) => {
  const resp = await fetch(`${url}/userinfo`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeaderMs,
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();
  return data;
};

export const ErrorLogInInstant = async (data) => {
  const requestOption = {
    method: "PUT",
    redirect: "follow",
    body: JSON.stringify(data),
  };
  fetch(`${instantUrl}/instant`, requestOption)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((err) => console.log("error: ", err));
};

export const leadCapture = async (token = null, body) => {
  const resp = await fetch(`${leadCaptureUrl}/leadcapture`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const data = await resp.json();
  return data;
};

export const profileInfo = async (token) => {
  const resp = await fetch(`${leadCaptureUrl}/profileinfo`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token} `,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(body),
  });

  const data = await resp.json();
  return data;
};

export const officeDetailExtract = async (token, customerActivityId) => {
  //
  const resp = await fetch(`${url}/office?customerId=${customerActivityId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token} `,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeaderMs,
    },
    // body: JSON.stringify(body),
  });
  const data = await resp.json();
  return data;
};

export const supportRequest = async (token, body) => {
  const resp = await fetch(`${leadCaptureUrl}/support`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token} `,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await resp.json();
  return data;
};

//TODO: ADD endpoint

// const data = await resp.json();
// return data;
// };
// {
//   "phoneNumber" : "string",
//   "videoTitle" : "string",
//   "type" : "pageVisit | optVerify | videoWatch",
//   "event" : "Play | Pause",
//   "watchedFull" : "bool" ,
//   "eventTimestamp" : "kab interact kara video se (video ki timestamp)",
//   "timestamp" : "number",
//   "address" : {
//       "city" : "",
//       "state" : "",
//       "lat" : "" ,
//       "long" : ""
//   },
//   "source" : {
//       "source" : "",
//       "medium" : "",
//       "campaign" :""
//   },
//   "pageUrl": ""
// }

export const sendTrackingInfo = async (token, body) => {
  // const resp = await fetch(``, {
  //   method: "POST",
  //   headers : {
  //     Authorization : `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(body)
  // })
  // const data = await resp.json();
  // return data;
};

export const NumInWords = (amount) => {
  // Constants
  let words = [];
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  let atemp = amount.split(".");
  let number = atemp[0].split(",").join("");
  let n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
    let n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let received_n_array = [];
    for (let i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (let i = 0, j = 1; i < 9; i++, j++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        if (n_array[i] === 1) {
          n_array[j] = 10 + parseInt(n_array[j], 10);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (let i = 0; i < 9; i++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value !== 0) {
        words_string += words[value] + " ";
      }
      if (
        (i === 1 && value !== 0) ||
        (i === 0 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i === 3 && value !== 0) ||
        (i === 2 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i === 5 && value !== 0) ||
        (i === 4 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += "Thousand ";
      }
      if (
        i === 6 &&
        value !== 0 &&
        n_array[i + 1] !== 0 &&
        n_array[i + 2] !== 0
      ) {
        words_string += "Hundred ";
      } else if (i === 6 && value !== 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
};

export function getFinancialYear() {
  const year = new Date().getFullYear();
  const nextYear = (year + 1).toString();
  return `${year}-${nextYear.slice(2, 4)}`;
}

export function getDayMonthAndYearFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthName = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  return { day, month, monthName, year };
}

export function convertNumberToCurrency(value) {
  if (typeof value === "string") {
    return parseInt(value).toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });
  }
  if (typeof value === "number") {
    return value.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });
  }
}
export function convertNumberToCurrencyWithoutSign(value) {
  if (typeof value === "string") {
    return parseInt(value).toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      currency: "INR",
    });
  }
  if (typeof value === "number") {
    return value.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      currency: "INR",
    });
  }
}

export const balanceConfirmation = async (token, activityId, phoneNumber) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Firebase-Env", apiHeader);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${url}/services/statement?customerActivityId=${activityId}`,
      requestOptions
    );
    const data = await response.json();

    if (data.success) {
      return {
        status: true,
        message: "",
        data: data.statements,
      };
    } else {
      const timestamp = Date.now();
      const body = {
        messageBody: {
          phoneNumber: phoneNumber,
          activityId: activityId,
          timestamp: Date.now(),
          date: new Date(timestamp).toLocaleString(),
        },
        subject: data.message,
      };
      const requestOption = {
        method: "POST",
        //TODO: Check headers here
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(body),
        redirect: "follow",
      };
      fetch(`${instantUrl}/growthfile/instant`, requestOption)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result.status);
          // console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });

      let message = "";
      if (
        data.message === "customerActivity phone number not same as yours..."
      ) {
        //
        const phone = window.localStorage.getItem("regNumber");

        message = `Please login using the registered contact number +91xxxxxx${
          phone !== null ? phone.slice(9, 13) : ""
        }. If you want to update the registered contact email or mobile number, please write to us at help@eqlfin.com`;
      } else {
        message =
          data.message !== undefined
            ? data.message
            : "Error in Pending Statement";
      }
      return {
        status: false,
        message: message,
        data: data.fetchedData,
      };
    }
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
};

export const pendingAmountCount = (demands) => {
  try {
    let pendingAmount = 0;
    let pendingValue = 0;

    let charge = 0;

    let sellerGSTNumber = demands[0]?.Logs.sellerGSTNumber;
    let sellerOfficeId = demands[0]?.Logs.sellersOfficeId;

    const sellerOffice = demands[0]?.Logs.sellerOffice;

    demands.forEach((demand) => {
      pendingAmount += demand.Logs.pendingAmount;

      if (demand.status === "PENDING") {
        const amount = demand.Logs.pendingAmount;
        const cgst = demand.attachment.cgst?.value || 0;
        const sgst = demand.attachment.sgst?.value || 0;
        const igst = demand.attachment.igst?.value || 0;

        const val = amount - (cgst + sgst + igst);
        const today = moment();
        const dueDate = moment(demand.attachment.dueDate.value, "Do MMM YYYY");
        const dayDiff = dueDate.diff(today, "days");
        if (dayDiff > 0) {
          charge += (val * 50 * dayDiff) / 100000;
        }

        pendingValue += val;
      }
    });
    // console.log(NumInWords(pendingAmount.toString()));

    return {
      pendingAmount: pendingAmount,
      words: NumInWords(pendingAmount),
      pendingCharge: charge,
      pendingValue: pendingValue,
      sellerGSTNumber: sellerGSTNumber,
      sellerOfficeId: sellerOfficeId,
      sellerOffice: sellerOffice,
    };
  } catch (error) {
    console.error(error);
  }
};

export const balanceConfirmApiCall = async (details) => {
  const resp = await fetch(`${url}/services/statement`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${details.token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify({
      statementActivityId: details.activityId,
      customerActivityId: details.customerActivityId,
    }),
  });

  const data = await resp.json();
  return data;
};

export const disputeApiCall = async (details) => {
  const resp = await fetch(`${url}/balanceconfirmation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${details.token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeaderMs,
    },
    body: JSON.stringify({
      data: details.statements,
    }),
  });

  const data = await resp.json();
  return data;
};

export const DeleteAccountApiCall = async (details) => {
  const resp = await fetch(`${leadCaptureUrl}/deleteaccount`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${details.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phoneNumber: details.phoneNumber,
    }),
  });

  const data = await resp.json();
  return data;
};
