import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import styles from "../../css/balanceConfirmation/balanceConfirmation.module.css";
export default function EarlyPaymentRequest({ details }) {
  // click on the button redirect to the payment page

  const navigate = useNavigate();

  const handleEarlyPaymentRequest = (e) => {
    e.preventDefault();
    const total = details.pendingCharge + details.pendingCharge * 0.18;
    window.localStorage.setItem("pendingValue", details.pendingValue);
    window.localStorage.setItem("sellerOfficeId", details.sellerOfficeId);

    navigate("/plan-buy", {
      state: {
        charge: details.pendingCharge,
        gst: (details.pendingCharge * 0.18).toFixed(2),
        totalAmount: total.toFixed(2),
        sellerOfficeId: details.sellerOfficeId,
        sellerGSTNumber: details.sellerGSTNumber,
        sellerOffice: details.sellerOffice,
        source: "earlyPayment",
      },
    });
  };
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Button
          className={styles.earlyPaymentRequestButton}
          variant="contained"
          onClick={handleEarlyPaymentRequest}
        >
          Part Payment Request
        </Button>
      </div>
    </>
  );
}
