import React from "react";
import moment from "moment";
import { NumInWords } from "../../utils";
import success from "../../images/commons/success.svg";

import styles from "../../css/balanceConfirmation/balanceConfirmation.module.css";

export default function ConfirmSuccess({ details }) {
  return (
    <>
      {!details ? (
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={success}
            alt="success"
            className={styles.referSuccessImage}
          />
          <h2>Thank you!</h2>
          <p style={{ textAlign: "center" }}>
            You don't have anything to confirm!{" "}
          </p>
        </section>
      ) : (
        <section>
          <h3>Confirmation Details</h3>
          <p>
            You have successfully confirmed that the balance of{" "}
            <span style={{ fontWeight: "600" }}>{details.office}</span> books as
            on{" "}
            <span style={{ fontWeight: "600" }}>
              {moment().format("MMM DD YYYY")}
            </span>
            &nbsp; is stated below:
          </p>

          <br />

          <table className={styles.balanceConfirmTable}>
            <tbody>
              <tr>
                <td
                  className={styles.tableHead}
                  style={{ border: "1px solid #4F4F4F" }}
                >
                  Total Balance (₹)
                </td>
                <td
                  className={styles.tableHead}
                  style={{ border: "1px solid #4F4F4F" }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {details.confirmAmount}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  className={styles.tableHead}
                  style={{
                    fontSize: "14px",
                    // fontWeight: "400",
                    color: "#4F4F4F",
                  }}
                >
                  Amount in Words:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {NumInWords(details.confirmAmount)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <div
            style={{
              display: "flex",
              gap: "10px",
              fontSize: "14px",
              color: "#4F4F4F",
            }}
          >
            <img src={success} alt="success" width={"20px"} />

            <p>
              Verified by{" "}
              <span style={{ fontWeight: "600" }}>
                {details.name} ({details.phoneNumber})
              </span>
            </p>
          </div>
        </section>
      )}
    </>
  );
}
