import styles from "../css/Aboutus.module.css";
// import { Link } from "react-router-dom";
// import logo from "../images/logo.svg";
import corevalue from "../images/about/corevalue.svg";
import kiran from "../images/about/kiran.webp";
import srinivasan from "../images/about/srinivasan.webp";
import ponnapa from "../images/about/ponnapa.webp";
import pallav from "../images/about/pallav.webp";
import sachin from "../images/about/sachin.webp";
import investor from "../images/about/investor.svg";
import missionheading from "../images/about/missionheading.webp";
import Footer from "./Common/Footer";
// import menu from "../images/homepage/menu.svg";
// import HeaderWithProducts from "./Common/HeaderWithProducts";
import experience from "../images/homepage/experience.svg";
import security from "../images/homepage/security.svg";
import handshake from "../images/homepage/handshake.svg";
import HeaderWithProducts from "./Common/HeaderWithProducts";
const AboutUs = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <div className={styles.container}>
        <HeaderWithProducts />

        <section className={styles.mainSection}>
          <div className={styles.pageHeader}>
            <h1 className={styles.heading}>
              We believe that <br />
              <span style={{ color: "#1904ff" }}>every rupee counts</span>
            </h1>
          </div>
          <div className={styles.para} style={{ textAlign: "center" }}>
            <p style={{ padding: "0px 10px" }}>
              EQLFIN enables businesses to achieve exponential financial growth
              by creating high-yield and risk-free opportunities to deploy daily
              cash balances. We provide up to 12% returns guaranteeing zero risk
              by improving your cash productivity.
            </p>
          </div>
        </section>

        <section className={styles.aboutSection}>
          <div className={styles.missionContainer}>
            <img loading="lazy" src={missionheading} alt="our mission" />
            <div className={styles.missionHeading}>
              Improve cash productivity for <br />
              <span style={{ color: "#f2994a", wordBreak: "keep-all" }}>
                1 million Indian businesses
                {/* <br /> */}
              </span>
            </div>
            <p
              className={styles.missionPara}
              style={{
                color: "white",
                textAlign: "center",
                maxWidth: "1400px",
              }}
            >
              We envision a future where every Indian business maximizes its
              cash flow, optimizes financial operations, and experiences
              sustained growth by improving cash productivity using their
              account payables. <br />
              <br />
              Through our innovative products and services, we equip businesses
              with the power to make well-informed financial decisions and
              maximize returns. We understand that cash flow is the lifeblood of
              any organization, and our goal is to help businesses unlock their
              full potential by optimizing their cash productivity.
              <br />
            </p>
          </div>
        </section>

        <section className={styles.mainSection}>
          <div className={styles.subHeading}>Our core value</div>
          <div className={styles.features}>
            <div className={styles.first}></div>
            <div className={styles.second}>
              <img loading="lazy" src={corevalue} alt="corevalue" />
            </div>
            <div className={styles.third}>
              <div className={styles.subHeading}>Right Conduct</div>
              EQLFIN holds the belief that when we integrate Right Conduct into
              business practices, value takes the form of honorable conduct,
              respect towards business partners and customers, generosity and
              reciprocity, and the ability to foster lasting business
              relationships. As this becomes a foundation for ethical and moral
              behavior, it instills qualities such as integrity, courage,
              environmental responsibility, and a sense of duty within
              institutions. Ultimately, the highest level of Right Conduct is
              reached when businesses operate in alignment with their highest
              ideals to deliver greater good for everyone.
            </div>
          </div>
        </section>

        {/*  */}
        <section className={styles.infoLight}>
          <div className={styles.headingWho}>Who are we?</div>
          <div className={styles.whoSection}>
            <div className={styles.whoCard}>
              <img loading="lazy" src={experience} alt="experience" />
              More than 20 years experience building corporate Fintech
              businesses in India.
            </div>
            <div className={styles.whoCard}>
              <img loading="lazy" src={security} alt="experience" />
              Best in class cloud infrastructure, data security, GSTIN
              validation & mobile OTP.
            </div>
            <div className={styles.whoCard}>
              <img loading="lazy" src={handshake} alt="experience" />
              Commitment to governance, regulations, compliance & infosec best
              practices.
            </div>
          </div>
        </section>

        <section className={styles.mainSection}>
          <div className={styles.moreSection}>
            <div className={styles.moreTextSection}>
              <div className={styles.subHeading}>More about EQLFIN</div>
              <div className={styles.moreSectionDesc}>
                EQLFIN was founded by a team of seasoned professionals with
                decades of experience in payments, accounting, e-commerce,
                technology, and the MSME sector. With a deep understanding of
                the intricacies of B2B payments, we are well-equipped to
                transform them into a lucrative revenue stream for businesses.
                Our cutting-edge, cloud-based platform incorporates essential
                technology for regularising collections, managing vendor
                financing and accounting, and is available to any company with a
                valid GST registration. Secure and simple to use, EQLFIN is a
                ready B2B fintech platform for businesses of all stripes.
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <div className={styles.bummer}></div>
        <section className={styles.mainSection}>
          <div className={styles.subHeading}>Our Founder & Advisors</div>
          <div className={styles.founderSection}>
            <img loading="lazy" src={kiran} alt="kiran" />
            <img loading="lazy" src={srinivasan} alt="srinivasan" />
            <img loading="lazy" src={pallav} alt="pallav" />
            <img loading="lazy" src={ponnapa} alt="ponnapa" />
            <img loading="lazy" src={sachin} alt="sachin" />
          </div>
        </section>
        <section className={styles.mainSection}>
          <div className={styles.subHeading}>Thanks to our investors</div>
          <div className={styles.features}>
            <div className={styles.first}></div>
            <div className={styles.second}>
              <img loading="lazy" src={investor} alt="investors" />
            </div>
            <div className={styles.third}>
              We are proud to be backed by marquee investors who believe in our
              vision. These include senior partners from the big four accounting
              firms, leading cloud technology professionals and founders of
              cutting-edge fintech firms.{" "}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
