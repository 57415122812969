import React, { useEffect, useState } from "react";
import styles from "../../css/website/home.module.css";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import invest from "../../images/homepage/invest/invest-illustration.png";
import api from "../../images/homepage/api-icon.png";
import otp from "../../images/homepage/otp-icon.png";
import gstIn from "../../images/homepage/customer-icon.png";
import rupee from "../../images/homepage/rupee-icon.png";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import logo from "../../images/homepage/logo.svg";
import circle from "../../images/homepage/hero/circle-2.png";
import mockup from "../../images/homepage/hero/mockup-2.png";
import Fade from "@mui/material/Fade";
import TypeWriter from "./TypeWriter";
import { leadCapture } from "../../utils";
export default function Home() {
  const [scroll, setScroll] = useState(false);
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Transition: Fade,
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const handleEmail = async (e) => {
    console.log("inside the button click");
    e.preventDefault();
    const Transition = Fade;

    const body = {
      name: "",
      phoneNumber: "",
      email: email,
      gstNumber: "",
      bookingTime: "",
      timezone: "Asia/Kolkata",
      source: {
        source: "",
        medium: "",
        campaign: "",
        customerId: "",
      },
      origin: "homepage",
      requestedUrl: window.location.href,
      referer: document.referrer || "",
      comment: `Homepage visit`,
      type: "homepage login",
    };

    await leadCapture(null, body);

    setOpenSnackbar({
      open: true,
      Transition,
    });
  };

  const handleClose = () => {
    setOpenSnackbar({
      ...openSnackbar,
      open: false,
    });
  };

  return (
    <>
      <section
        className={`${styles.hero} ${styles.bgImg} ${styles.firstScroll}`}
      >
        <header
          className={
            scroll
              ? `${styles.navbar} ${styles.navbar_expand_lg} ${styles.box_shadow}`
              : ``
          }
        >
          <nav
            className={`${styles.navbar} ${styles.navbar_expand_lg}`}
            id="mainNav"
          >
            <div className={styles.container}>
              <Link className={styles.navbar_brand} to="/">
                <img src={logo} alt="Logo" className={styles.logo} />
              </Link>
            </div>
          </nav>
        </header>

        <div className={styles.container}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <h1
                  className={`${styles.mgn_bottom_20} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                  Instant cash advance against receivables
                </h1>
                <p className={styles.firstScrollPara}>
                  <span
                    className={`${styles.animate__} ${styles.animate__fadeInUp} ${styles.animatedlarge} ${styles.type} `}
                  >
                    <TypeWriter
                      text={
                        "Avoid loans & cut interest cost. Get an instant cash advance against your receivables for day to day expenses @ Rs 50/day for Rs 1,00,000 advance. Use Eqlfin for day to day expenses & remove debt burden for your company!"
                      }
                      delay={100}
                      infinite
                    />
                  </span>
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div
                  id="firstScrollImagePart"
                  class={` ${styles.animate__animated} ${styles.animate__fadeInTopRight} ${styles.hero__img}`}
                >
                  <img src={circle} alt="circle" class={styles.hero__circle} />
                  <img src={mockup} alt="mockup" class={styles.hero__mock} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <div className={styles.client}>
        <div className={styles.clientContainer}>
          <div
            className={`${styles.client__wrapper} ${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.text_center} ${styles.text_white}`}
          >
            <h3
              className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.text_center} ${styles.text_white}`}
            >
              <span> Do you run a business?</span> <br />
              Upload pending invoices to get limit sanctioned. Pay fees to withdraw. Simple.
            </h3>
          </div>
        </div>
      </div>

      <section className={styles.invest} id="features">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={`${styles.invest__thumb} ${styles.animate__animated} ${styles.animate__fadeInLeft}`}
              >
                <img src={invest} alt="Invest" className={styles.investImage} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={`${styles.invest__content} `}>
                <h6
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                 The instant source of cash your business needs

                </h6>
                <h2
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.mainGradient}`}
                >
                 ADVANCE AGAINST RECEIVABLES

                </h2>

                <p
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.mgn_bottom_20}`}
                >
                  The entire process of getting loans, using the funds &
                  accounting for it is complicated with all kinds of hidden fees
                  and costs. Keep it simple, just take an advance against a
                  one-time fee paid upfront. That’s it.
                </p>

                <p
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.mgn_bottom_20}`}
                >
                  At EQLFIN, we keep cash simple, you take it when you want. Adjust this advance against your receivable and that’s it. Leave the rest to us. 


                </p>

                <p
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.mgn_bottom_20}`}
                >
                 You can use this cash for regular expenses and it is paid into your normal collection account so it will offset your CC/OD limit if any and reduce interest cost right away.
                </p>

                <p
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp} ${styles.mgn_bottom_20}`}
               style={{fontWeight:"bold",fontSize:"24px"}} >
                 The simplest way to get cash

                </p>

                <div className={`${styles.accounting_points}`}>
                  <ul
                    className={`${styles.animate__animated} ${styles.animate__fadeInUp}`}
                  >
                    <li>Advance is given against verified receivables only</li>
                    <li>No need to return the advance, it is adjusted </li>
                    <li>
                      Advance is calculated basis invoice value net of GST & TDS
                    </li>
                    <li>Upload more invoices to get more limit</li>
                    <li>
                      Regular users get higher limits, so use it regularly
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </section>

      {/* Second Scroll */}

      {/* <section
        className={`${styles.work} ${styles.bgImg}`}
        id={styles.workBgImg}
      >
        <div className={styles.choice__title}>
          <h2
            className={`${styles.text_center} ${styles.mainGradient} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
          >
            Start today with a Rs 5000 top-up! Pay as low as Rs 50 per invoice…
          </h2>
          <h6
            className={`${styles.text_center} ${styles.mgn_bottom_20} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
          >
            Features
          </h6>
        </div>

        <div className={styles.featureContainer}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <div
                  className={`${styles.work__item} ${styles.text_center} ${styles.work__item__primary} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                  <img src={rupee} alt="Install" />
                  <h6 className={styles.text_center}>
                    No charges per user or per month
                  </h6>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div
                  className={`${styles.work__item__secondary} ${styles.work__item} ${styles.text_center} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                  <img src={gstIn} alt="Account" />
                  <h6 className={styles.text_center}>
                    Connect to customers and branches using GSTN.
                  </h6>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div
                  className={`${styles.work__item__third} ${styles.work__item} ${styles.text_center} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                  <img src={otp} alt="Secure" />
                  <h6 className={styles.text_center}>
                    Log in with OTP for sales and admin | Upload data from
                    Excel.
                  </h6>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div
                  className={` ${styles.work__item} ${styles.text_center} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                >
                  <img src={api} alt="Secure" />
                  <h6 className={styles.text_center}>
                    Integrate <br />
                    with APIs
                  </h6>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section> */}

      {/* Footer */}

      <footer className={styles.bgImg} id={styles.footerBgImg}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div
                className={`${styles.footer__cta} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
              >

                <h3 style={{color:"#27346d",textAlign:"center",marginBottom:"10px"}}>    Upload an invoice to withdraw today
</h3>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    className={styles.logo}
                    style={{ marginTop: "0" }}
                  />
                </Link>

                <form
                  action="#"
                  method="post"
                  className={`${styles.animate__animated} ${styles.animate__fadeInUp}`}
                  onSubmit={handleEmail}
                >
                  <div className={styles.input__group}>
                    <input
                      type="email"
                      name="subscribe"
                      id="subscribe"
                      required="required"
                      placeholder="Your Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit">
                      <SendRoundedIcon style={{ color: "#fff" }} />
                    </button>
                  </div>
                </form>
                <div className={styles.footer__social}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/eqlfin/"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://twitter.com/eqlfinpayments"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/OnDutyApp"
                  >
                    <FacebookRoundedIcon />
                  </a>
                </div>
              </div>
              <div
                className={`${styles.footer__credit} ${styles.animate__animated} ${styles.animate__fadeInUp}`}
                style={{ textAlign: "center", marginTop: "40px" }}
              >
                <p
                  className={styles.text_center}
                  style={{ textAlign: "center" }}
                >
                  Copyright © 2023.All Rights Reserved by EQLFIN
                </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </footer>

      <Snackbar
        open={openSnackbar.open}
        onClose={handleClose}
        TransitionComponent={openSnackbar.Transition}
        message="Thank you for your interest. We will get back to you soon."
        key={openSnackbar.Transition?.name}
        autoHideDuration={5000}
      />
    </>
  );
}
