import React, { useEffect, useState } from "react";
import styles from "../../css/login.module.css";
import { Button, TextField } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebaseSetup";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import logo from "../../images/logo.svg";
import EarlyPaymentTemplate from "../Earlypayment/EarlyPaymentTemplate";
import { phoneNumberRegex, leadCapture } from "../../utils";
import { useAuth } from "../../Contexts/AuthContext";

const Login = () => {
  const queryParamDetails = JSON.parse(
    window.sessionStorage.getItem("urlQueryParams")
  );
  const [queryParams] = useSearchParams();

  const source = queryParams?.get("source");
  const campaign = queryParams?.get("campaign");
  const id = queryParams?.get("id");
  const campaignId = queryParams?.get("campaignId");
  const phoneNumberQuery = queryParams?.get("phoneNumber");

  useEffect(() => {
    if (source !== null && campaign !== null && id !== null) {
      
      window.localStorage.setItem("customerActivityId", id);
      window.localStorage.setItem(
        "sourceDetails",
        JSON.stringify({
          campaign: campaign,
          source: source,
          id: id,
          campaignId: campaignId,
        })
      );
      window.localStorage.setItem(
        "regNumber",
        `+${phoneNumberQuery.trimStart()}`
      );
    }
  }, []);
  const { state } = useLocation();
  const navigate = useNavigate();

  const { token } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [resendButton, setResendButton] = useState(true);
  const [seconds, setSeconds] = useState(20);
  const [otpLoading, setOtpLoading] = useState(false);
  const [screen, setScreen] = useState("login");
  const [loading, setLoading] = useState(false);

  //errors
  const [phoneError, setPhoneError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  useEffect(() => {
    const verify = window.localStorage.getItem("_grecaptcha");
    if (verify) {
      if (token !== null && token !== "") {
        setTimeout(() => {
          navigate("/balance-confirmation");
        }, 4000);
      }
    }
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        setResendButton(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    auth
      .signOut()
      .then((res) => {
        localStorage.removeItem("officeDetail");
        sessionStorage.clear();
      })
      .catch((err) => console.log(err));
  }, []);

  const resendButtonClick = async (e) => {
    e.preventDefault();
    setResendButton(false);

    await signInWithPhoneNumber(
      auth,
      `+91${phoneNumber}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((err) => console.log("signin function error: ", err));
    setSeconds(20);
  };

  const otpTrigger = async () => {
    // e.preventDefault();
    setResendButton(false);

    if (phoneNumberRegex.test(phoneNumber)) {
      setOtpLoading(true);
      setPhoneError(false);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-containers",
        {
          size: "invisible",
          "expired-callback": () => {},
        },
        auth
      );

      window.recaptchaVerifier.render();
      await signInWithPhoneNumber(
        auth,
        `+91${phoneNumber}`,
        window.recaptchaVerifier
      )
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setOtpLoading(false);
        })
        .catch((err) => {
          window.recaptchaVerifier
            .render()
            .then((widgetId) => window.grecaptcha.reset(widgetId));
        });
      setLoading(false);
      setScreen("otpScreen");
    } else {
      if (phoneNumber === "" || !phoneNumberRegex.test(phoneNumber)) {
        setPhoneError(true);
      }
    }
    setLoading(false);
    setSeconds(20);
  };

  const otpVerify = async (e) => {
    e.preventDefault();

    if (otp.length === 6) {
      setOtpError(false);
      let confirmationResult = window.confirmationResult;
      let otpResult = "";
      try {
        setLoading(true);
        otpResult = await confirmationResult.confirm(otp);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOtpError(true);
      }
      const otpverificationResult = await otpResult;
      if (otpverificationResult) {
        if (state?.currentComponentState) {
          if (
            queryParamDetails?.office &&
            queryParamDetails?.eligibleAmount &&
            queryParamDetails?.fees &&
            queryParamDetails?.campaign
          ) {
            navigate(
              `/eqlfinfirst/earlypayments?office=${queryParamDetails?.office}&amount=${queryParamDetails?.eligibleAmount}&fees=${queryParamDetails?.fees}&campaign=${queryParamDetails?.campaign}`
            );
          } else {
            navigate("/eqlfinfirst/earlypayments");
          }
        } else {
          //
          console.log("inside the condition");
          if (source !== null && source !== "") {
            const body = {
              name: "",
              phoneNumber: phoneNumber,
              email: "",
              gstNumber: "",
              bookingTime: "",
              timezone: "Asia/Kolkata",
              source: {
                source: campaignId,
                medium: source,
                campaign: campaign,
                customerId: id,
              },
              origin: "balance-confirmation",
              requestedUrl: window.location.href,
              referer: document.referrer || "",
              comment: `balance-confirm page visited using this customer activityId ${id}`,
              type: "balance-confirm login",
            };
            await leadCapture(null, body);
          }

          setTimeout(() => {
            navigate("/balance-confirmation", {
              state: {
                data: {},
                origin: "login",
              },
            });
          }, 4000);
        }
      } else {
        setOtpError(true);
      }
    } else {
      setOtpError(true);
    }
  };

  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    if (e.target.value === "" || e.target.value.length !== 6) {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
    setOtp(e.target.value);
  };

  const handleSubmit = () => {
    if (!phoneError) {
      otpTrigger();
    }
  };

  return (
    <EarlyPaymentTemplate showHeader={true} showProfile={false}>
      <div className={styles.container}>
        <section className={styles.mainSection}>
          {screen === "otpScreen" ? (
            <>
              <h1 className={styles.heading} id="transition-modal-title">
                Verify Phone Number
              </h1>

              <div className={styles.subHeading}>
                Enter the 6-digit code we sent to
                <span style={{ color: "#1904ff" }}>+91-{phoneNumber}</span>
              </div>
              <div className={styles.subHeading} style={{ marginTop: "10px" }}>
                Enter OTP
              </div>
              <div className={styles.formGroup}>
                <TextField
                  inputProps={{
                    maxLength: 6,
                  }}
                  sx={{
                    background: "transparent !important",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                  label="OTP"
                  required
                  onChange={handleOtpChange}
                  value={otp}
                  error={otpError}
                  helperText={otpError && "Enter a valid OTP"}
                />
              </div>
              <div className={styles.buttonGroup}>
                <div
                  className={styles.backButton}
                  onClick={() => setScreen("form")}
                >
                  Back
                </div>
                <button className={styles.submitButton} onClick={otpVerify}>
                  {loading ? "Please Wait... " : "Verify"}
                </button>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {!resendButton ? (
                  <div>
                    Resend Code in 00:{seconds < 10 ? `0${seconds}` : seconds}{" "}
                  </div>
                ) : (
                  <Button onClick={resendButtonClick}>Resend</Button>
                )}
              </div>
              <div
                style={{
                  margin: "20px 10px 0px 10px",
                  textAlign: "center",
                  maxWidth: "400px",
                }}
                className={styles.bottomText}
              >
                By tapping Verify, you are indicating that you accept our{" "}
                <Link to="/security-privacy" target="_blank">
                  <span style={{ color: "#1904ff" }}> Terms of Service </span>
                </Link>
                and
                <Link to="/security-privacy" target="_blank">
                  <span style={{ color: "#1904ff" }}> Privacy Policy.</span>
                </Link>
              </div>
            </>
          ) : (
            <>
              <h1 className={styles.heading} id="transition-modal-title">
                Verify Phone Number
              </h1>
              <div className={styles.subHeading}>Enter Phone No.</div>
              <div className={styles.formGroup}>
                <TextField
                  inputProps={{
                    maxLength: 10,
                  }}
                  sx={{
                    background: "transparent !important",
                    maxWidth: "400px",
                    width: "100%",
                    marginTop: "0px",
                  }}
                  label="Phone Number"
                  required
                  onChange={handlePhoneChange}
                  value={phoneNumber}
                  error={phoneError}
                  helperText={phoneError && "Enter a valid phone number"}
                />

                <button
                  type="submit"
                  className={styles.submitButton}
                  onClick={handleSubmit}
                >
                  {otpLoading ? "Please Wait..." : "Continue"}
                </button>
              </div>
              <div className={styles.bottomText}>
                An SMS may be sent. Message & data rates may apply.
              </div>
            </>
          )}
        </section>

        <div id="recaptcha-containers"></div>
        <div id="recaptcha-containers-resend"></div>
      </div>
    </EarlyPaymentTemplate>
  );
};

export default Login;
