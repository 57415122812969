import React, { useState } from "react";
import {
  gstVerification,
  phoneNumberRegex,
  regEmail,
  supportRequest,
} from "../../utils";
import styles from "../../css/LeadForm.module.css";
import { TextField } from "@mui/material";
import { useTrackAuth } from "../../Contexts/TrackContext";

const LeadSignupForm = ({ screen = "form", setScreen }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingTime, setBookingTime] = useState("");
  const [amount, setAmount] = useState(0);

  //error states
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const { token } = useTrackAuth();

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    name === "" ? setNameError(true) : setNameError(false);
    phoneNumber === "" ? setPhoneError(true) : setPhoneError(false);
    email === "" ? setEmailError(true) : setEmailError(false);
    date === "" ? setDateError(true) : setDateError(false);
    gst === "" ? setGstError(true) : setGstError(false);

    if (
      name !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      date !== "" &&
      gst !== "" &&
      !nameError &&
      !phoneError &&
      !emailError &&
      !dateError &&
      !gstError
    ) {
      console.log("otp triggering");
      // otpTrigger();
      const body = {
        origin: "support",
        template: "profile",
        name: name,
        phoneNumber: `+91${phoneNumber}`,
        email: email,
        gstNumber: gst,
        bookingTime: bookingTime,
        timezone: "Asia/Kolkata",
        source: {
          source: "support",
          medium: "",
          campaign: "",
        },
        amount: amount,
      };
      setLoading(true);
      const supportApi = await supportRequest(token, body);
      setLoading(false);
      // console.log(supportApi);
      if (supportApi.success) {
        setScreen("thankyou");
      }
    }
  };

  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    if (e.target.value === "" || !regEmail.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(e.target.value);
  };

  const handleGSTOnChange = (e) => {
    if (e.target.value.length === 15) {
      if (gstVerification(e.target.value)) {
        setGstError(false);
        setGst(e.target.value);
      } else {
        setGstError(true);
      }
    } else {
      setGstError(true);
    }
    setGst(e.target.value);
  };

  const handleDateChange = (e) => {
    // if (e.target.value === "") {
    //   setDateError(true);
    // } else {
    //   setDateError(false);
    // }
    const d = Date.parse(e.target.value);
    const today = new Date();
    if (d < Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) {
      setDateError(true);
    } else {
      setDateError(false);
      setDate(e.target.value);
      setBookingTime(d);
    }
  };

  const handleAmountChange = (e) => {
    if (parseInt(e.target.value) >= 0 && e.target.value !== "") {
      setAmountError(false);
      setAmount(parseInt(e.target.value));
    } else {
      setAmountError(true);
    }
  };

  function clearData() {
    setName("");
    setPhoneNumber("");
    setEmail("");
    setGst("");
    setDate("");
    setBookingTime("");
    setAmount("");
    setScreen("form");
  }

  const toDisplay = () => {
    switch (screen) {
      case "form":
        return (
          <>
            <div className={styles.formGroup}>
              <TextField
                className={styles.formField}
                label="Full Name"
                type="text"
                required
                value={name}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setNameError(true);
                  } else {
                    setNameError(false);
                  }
                  setName(e.target.value);
                }}
                error={nameError}
                helperText={nameError ? "A name is required" : ""}
              />
              <TextField
                className={styles.formField}
                inputProps={{
                  maxLength: 10,
                }}
                sx={{ background: "transparent !important", maxWidth: "300px" }}
                label="Official Phone Number"
                required
                onChange={handlePhoneChange}
                value={phoneNumber}
                error={phoneError}
                helperText={phoneError && "Enter a valid phone number"}
              />
              <TextField
                className={styles.formField}
                label="Official Email ID"
                type="email"
                required
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Enter a valid email" : ""}
              />
              <TextField
                className={styles.formField}
                label="GSTIN"
                type="text"
                required
                inputProps={{
                  maxLength: 15,
                }}
                value={gst}
                onChange={handleGSTOnChange}
                error={gstError}
                helperText={gstError ? "Enter a valid GSTIN" : ""}
              />
              <TextField
                className={styles.formField}
                label="When do you want a call back"
                type="date"
                InputLabelProps={{ shrink: true, required: true }}
                inputProps={{
                  style: { textTransform: "uppercase" },
                  min: disablePastDate(),
                }}
                value={date}
                onChange={handleDateChange}
                error={dateError}
                helperText={dateError ? "Select a valid date" : ""}
              />
              <TextField
                className={styles.formField}
                label="Additional Income (Cr.)"
                type="number"
                value={amount}
                required
                onChange={handleAmountChange}
                error={amountError}
                helperText={amountError ? "Enter a valid Amount" : ""}
              />
              <button
                type="submit"
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                {loading ? "Please Wait..." : "Continue"}
              </button>
            </div>
          </>
        );
      case "thankyou":
        return (
          <>
            <div className={styles.headingContainer}>
              <div className={styles.heading} id="transition-modal-title">
                Thank You!
              </div>
              <div
                onClick={clearData}
                style={{ color: "#1904ff", cursor: "pointer" }}
              >
                Back
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading} id="transition-modal-title">
            New Lead
          </h1>
        </div>

        {toDisplay()}
      </>
    </div>
  );
};

export default LeadSignupForm;
