import styles from "../../css/homepage.module.css";
import Footer from "../Common/Footer";

import liquidity from "../../images/homepage/liquidity.svg";

import reward from "../../images/solution/reward.svg";
import HeaderWithProducts from "../Common/HeaderWithProducts";

const RewardBenefits = () => {
  return (
    <div className={styles.container}>
      <HeaderWithProducts />

      <section className={styles.mainSection}>
        <div className={styles.mainInnerSection}>
          <div className={styles.heading}>Pay Smart, Earn More</div>
          <div className={styles.para}>
            Pay your vendors before due date from the excess cash lying in your
            current account using EQLFIN Payment Service and earn additional
            cashbacks
          </div>
        </div>
        <img src={reward} alt="header" className={styles.headerImg} />
      </section>

      <div
        style={{
          backgroundColor: "#f2f2f273",
          display: "flex",
          alignItems: "center",
          padding: "0px 4%",
        }}
      >
        <section className={styles.feature}>
          <img
            loading="lazy"
            src={liquidity}
            alt="liquidity"
            className={styles.headerImg}
          />
          <div className={styles.rightText}>
            <div className={styles.heading}>
              Liquidity, <br /> when you need it.
            </div>
            <div className={styles.para}>
              EQLFIN offers on-tap liquidity with zero investment and no lock-in
              period, eliminating the risks associated with traditional
              investments. Simply pay your vendors before the due date and
              generate an additional income on your accounts payables. This
              aggregates to a higher rate of risk-free returns in no time
            </div>
          </div>
        </section>
      </div>

      <section className={styles.benefitSection}>
        <div className={styles.heading}>Key Benefits</div>
        <div className={styles.benefitInnerSection2}>
          <div>
            <div className={styles.subHeading}>Zero upfront costs</div>
            <div className={styles.benefitsPara}>
              We retain a portion of the fees paid by vendors for building and
              maintenance of the platform, so you do not pay anything out of
              pocket. Not even payment gateway or collection charges.
            </div>
          </div>
          <div>
            <div className={styles.subHeading}>No fixed fund allocation</div>
            <div className={styles.benefitsPara}>
              Pay your vendor invoices before the due date to generate up to 12%
              returns. This only reduces your own payable outstanding with no
              other deposit or allocation of funds.
            </div>
          </div>

          <div>
            <div className={styles.subHeading}>
              Maximising income with 100% coverage
            </div>
            <div className={styles.benefitsPara}>
              Our platform is designed to maximise income for you and covers
              each and every vendor's invoice including B & C category vendors
              who do not have the system maturity for complex discounting and
              bidding workflows
            </div>
          </div>

          <div>
            <div className={styles.subHeading}>Secure and confidential</div>
            <div className={styles.benefitsPara}>
              We only need vendor GSTIN, invoice number, total amount, delivery
              & payment confirmations as data points to generate orders and we
              completely avoid confidential data like what you are buying, how
              much, and the quantity. So there is no way your confidential data
              can get leaked from our systems
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles.workSection}>
        <div className={styles.heading}>How EQLFIN Works?</div>
        <div className={styles.imageContainer}>
          <img
            src={treasuryWorks}
            className={styles.worksImg}
            alt="treasury works"
          />
        </div>
       
      </section> */}

      {/* <section className={styles.infoLight}>
        <div className={styles.heading}>Who are we?</div>
        <div className={styles.whoSection}>
          <div className={styles.whoCard}>
            <img loading="lazy" src={experience} alt="experience" />
            More than 20 years experience building corporate Fintech businesses
            in India.
          </div>
          <div className={styles.whoCard}>
            <img loading="lazy" src={security} alt="experience" />
            Best in class cloud infrastructure, data security, GSTIN validation
            & mobile OTP.
          </div>
          <div className={styles.whoCard}>
            <img loading="lazy" src={handshake} alt="experience" />
            Commitment to governance, regulations, compliance & infosec best
            practices.
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default RewardBenefits;
