import { Link } from "react-router-dom";
import profileIcon from "../../images/commons/profileicon.svg";
import logo from "../../images/logo.svg";
import styles from "../../css/CommonComponent/HeaderWIthProfile.module.css";

const HeaderWIthProfile = ({ showProfile }) => {
  return (
    <section className={styles.headerContainer}>
      <Link to="/">
        <img loading="lazy" src={logo} alt="logo" style={{ width: "70%" }} />
      </Link>
      <span className={styles.linkSection}>
        <Link to="/earlypayment/profile">
          {showProfile && (
            <img
              loading="lazy"
              src={profileIcon}
              //   className={styles.profileIcon}
              alt="menu icon"
            />
          )}
        </Link>
      </span>
    </section>
  );
};

export default HeaderWIthProfile;
