import React, { useEffect } from "react";
import logo from "../images/LogoNew.svg";
import errorImg from "../images/404.svg";
import { useNavigate } from "react-router-dom";
export default function NonExistRoute() {
  const history = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      history("/");
    }, 5000);
  }, []);
  return (
    <>
      <div className="erroPageDiv">
        <img src={logo} alt="logo" className="errorpageLogo" />

        <img src={errorImg} alt="errorImage" className="errorImage" />
        <h1 style={{color:"#333",fontSize:"56px",textAlign:"center"}}>404</h1>
        <h3 style={{textAlign:"center",color:"#333",fontSize:"24px"}}>Page Not Found</h3>
        <p style={{color:"#828282",fontSize:"21px",textAlign:"center"}}>The page you requested could not be found </p>

        <p style={{textAlign:"center",color:"#828282",fontSize:"16px"}}>
          you will automatically redirected to the product <br /> page in 5 seconds
          <a href="/" style={{textDecoration:"none",color:"#1904ff"}}> Click here</a> to go there now
        </p>
      </div>
    </>
  );
}
