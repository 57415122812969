import React from "react";
import HeaderWithProducts from "../Common/HeaderWithProducts";
import styles from "../../css/homepage.module.css";
import personal from "../../images/solution/paFirstScroll.svg";
import Footer from "../Common/Footer";
import pc2 from "../../images/solution/paSecondScroll.svg";

export default function PersonalAssistant() {
  return (
    <>
      <div className={styles.container}>
        <HeaderWithProducts />

        <section className={styles.mainSection}>
          <div className={styles.mainInnerSection}>
            <div className={styles.heading}>
              Achieve more, stress less: let your personal assistant take the
              lead
            </div>
            <div className={styles.para}>
              Do you have any specific issues that require additional follow up,
              just let us know and we will take care.
            </div>
          </div>
          <img
            src={personal}
            alt="header"
            className={styles.headerImg}
            id={styles.balanceConfirmationImage}
          />
        </section>

        <div
          style={{
            backgroundColor: "#f2f2f273",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 4%",
            width: "100%",
          }}
        >
          <section className={styles.feature}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                loading="lazy"
                src={pc2}
                alt="balance confirmation"
                className={styles.headerImg2}
                style={{ maxWidth: "400px" }}
              />
            </div>
            <div className={styles.rightText} style={{ maxWidth: "1000px" }}>
              <div className={styles.miniHeading}>EQLFIN Premium</div>
              <div className={styles.heading2}>
                Provides seamless customer communication and query resolution
              </div>
              <div className={styles.para2}>
                we help you get any of your queries answered from your customers
                which would require additional followup. It may include errors
                in invoice details, payment details or any issues regarding the
                transaction
              </div>
            </div>
          </section>
        </div>

        <section className={styles.benefitSection}>
          <div className={styles.heading}>Key Benefits</div>
          <div className={styles.benefitInnerSection2}>
            <div>
              <div className={styles.subHeading}>
                Better customer relationship
              </div>
              <div className={styles.benefitsPara}>
                We can act as a liaison between you and your customers. We can
                handle communication, address your inquiries or concerns, and
                facilitate smooth part payments processing.
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Quick resolution</div>
              <div className={styles.benefitsPara}>
                Get quick support in resolving discrepancies in case of any
                mismatches in the invoice and payment data
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Improved productivity</div>
              <div className={styles.benefitsPara}>
                Save your team’s time that is invested in calling/emailing for
                query resolution. Focus on your core business activities that
                improves efficiency, we will handle the rest
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Customised support</div>
              <div className={styles.benefitsPara}>
                Get tailored support based on your specific needs and
                preferences. This can include personalised recommendations,
                assistance with invoice or payment, and addressing unique
                requirements
              </div>
            </div>
          </div>
        </section>

        {/* <section className={styles.workSection}>
          <div className={styles.heading}>How EQLFIN works?</div>
          <div className={styles.imageContainer}>
            <img src={pcworks} className={styles.worksImg} alt="works" />
          </div>

        
        </section> */}

        <Footer />
      </div>
    </>
  );
}
