import React, { useEffect } from "react";
import AdCampaignHeaderLogin from "./webApp/AdCampaignHeaderLogin";
import styles from "../css/PurchaseSuccess.module.css";
import PurchaseComponent from "../images/PurchaseSuccess.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import { useState } from "react";
import PageLoadingBox from "./PageLoadingBox";
import { useEarlyPaymentContextDetails } from "../Contexts/EarlyPaymentContexts";
import { earlyPaymentRequest } from "../utils";

const PurchaseSuccess = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const paymentStatus = queryParams?.get("paymentStatus").toLocaleLowerCase();

  const { token, currentUser, earlyPaymentBalance, userDetails } = useAuth();

  // const { earlyPaymentAmount, processingFees } =
  //   useEarlyPaymentContextDetails();

  useEffect(() => {
    const verify = localStorage.getItem("_grecaptcha");

    if (verify) {
      if (token !== null) {
        console.log(paymentStatus);
        if (paymentStatus === "confirmed") {
          const body = {
            amount: parseFloat(window.localStorage.getItem("pendingValue")),
            officeId: window.localStorage.getItem("sellerOfficeId"),
          };
          navigate("/balance-confirmation", {
            state: {
              status: "confirmed",
             
            },
          });
         
        } else {
          navigate("/balance-confirmation", {
            state: {
              status:paymentStatus          
            },
          });
        }
      }
    }
  }, [currentUser]);

  return loading ? (
    <PageLoadingBox />
  ) : (
    <>
      <AdCampaignHeaderLogin />

      <div className={styles.container}>
        {paymentStatus === "confirmed" ? (
          <>
            <img alt="" src={PurchaseComponent} />
            <div className={styles.heading}>Purchase Successful</div>
          </>
        ) : (
          <div className={styles.heading}>Purchase Failed</div>
        )}
      </div>
    </>
  );
};

export default PurchaseSuccess;
