import React, { useState } from "react";
import {
  gstVerification,
  phoneNumberRegex,
  regEmail,
  supportRequest,
} from "../../utils";
import styles from "../../css/LeadForm.module.css";
import { TextField } from "@mui/material";
import { useTrackAuth } from "../../Contexts/TrackContext";

const SecondForm = ({ screen = "form", setScreen }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [office, setOffice] = useState("");

  //error states
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [officeError, setOfficeError] = useState(false);

  const { token } = useTrackAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    name === "" ? setNameError(true) : setNameError(false);
    phoneNumber === "" ? setPhoneError(true) : setPhoneError(false);
    email === "" ? setEmailError(true) : setEmailError(false);
    amount === "" ? setAmountError(true) : setAmountError(false);
    office === "" ? setOfficeError(true) : setOfficeError(false);

    if (
      name !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      amount !== "" &&
      office !== "" &&
      !nameError &&
      !phoneError &&
      !emailError &&
      !amountError &&
      !gstError &&
      !officeError
    ) {
      console.log("otp triggering");
      // console.log(token);

      const body = {
        origin: "support",
        template: "gstr2b",
        amount: amount,
        office: office,
        name: name,
        phoneNumber: `+91${phoneNumber}`,
        email: email,
        gstNumber: gst,
        bookingTime: "",
        timezone: "Asia/Kolkata",
        source: {
          source: "support",
          medium: "",
          campaign: "",
        },
      };
      setLoading(true);
      const supportApi = await supportRequest(token, body);
      setLoading(false);
      // console.log(supportApi);
      if (supportApi.success) {
        setScreen("thankyou");
      }
    }
  };

  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    if (e.target.value === "" || !regEmail.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(e.target.value);
  };

  const handleGSTOnChange = (e) => {
    if (e.target.value.length === 15) {
      if (gstVerification(e.target.value)) {
        setGstError(false);
        setGst(e.target.value);
      } else {
        setGstError(true);
      }
    } else {
      setGstError(true);
    }
    setGst(e.target.value);
  };

  const handleAmountChange = (e) => {
    if (parseInt(e.target.value) > 0) {
      setAmountError(false);
    } else {
      setAmountError(true);
    }
    setAmount(parseInt(e.target.value));
  };

  function clearData() {
    setName("");
    setPhoneNumber("");
    setEmail("");
    setGst("");
    setAmount("");
    setOffice("");
    setScreen("form");
  }

  const toDisplay = () => {
    switch (screen) {
      case "form":
        return (
          <>
            <div className={styles.formGroup}>
              <TextField
                className={styles.formField}
                label="Full Name"
                type="text"
                required
                value={name}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setNameError(true);
                  } else {
                    setNameError(false);
                  }
                  setName(e.target.value);
                }}
                error={nameError}
                helperText={nameError ? "A name is required" : ""}
              />
              <TextField
                className={styles.formField}
                inputProps={{
                  maxLength: 10,
                }}
                sx={{ background: "transparent !important", maxWidth: "300px" }}
                label="Official Phone Number"
                required
                onChange={handlePhoneChange}
                value={phoneNumber}
                error={phoneError}
                helperText={phoneError && "Enter a valid phone number"}
              />
              <TextField
                className={styles.formField}
                label="Official Email ID"
                type="email"
                required
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Enter a valid email" : ""}
              />
              <TextField
                className={styles.formField}
                label="GSTIN"
                required
                type="text"
                inputProps={{
                  maxLength: 15,
                }}
                value={gst}
                onChange={handleGSTOnChange}
                error={gstError}
                helperText={gstError ? "Enter a valid GSTIN" : ""}
              />
              <TextField
                className={styles.formField}
                label="Office Name"
                type="text"
                required
                value={office}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setOfficeError(true);
                  } else {
                    setOfficeError(false);
                  }
                  setOffice(e.target.value);
                }}
                error={officeError}
                helperText={officeError ? "Office name is required" : ""}
              />
              <TextField
                className={styles.formField}
                label="Vendor Payments (Cr.)"
                type="number"
                value={amount}
                required
                onChange={handleAmountChange}
                error={amountError}
                helperText={amountError ? "Enter a valid revenue" : ""}
              />
              <button
                type="submit"
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                {loading ? "Please Wait..." : "Continue"}
              </button>
            </div>
          </>
        );
      case "thankyou":
        return (
          <>
            <div className={styles.headingContainer}>
              <div className={styles.heading} id="transition-modal-title">
                Thank You!
              </div>
              <div
                onClick={clearData}
                style={{ color: "#1904ff", cursor: "pointer" }}
              >
                Back
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading} id="transition-modal-title">
            GSTR2b Amount Calculation
          </h1>
        </div>

        {toDisplay()}
      </>
    </div>
  );
};

export default SecondForm;
