import React, { useState } from "react";
import HeaderWithoutProducts from "../Common/HeaderWithoutProducts";

import { TextField } from "@mui/material";

import styles from "../../css/mobileApp/mobileApp.module.css";
import { phoneNumberRegex } from "../../utils";

export default function MobileLogin() {
  const [screen, setScreen] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e.target.value);
  };

  // const handleOtpChange = (e) => {
  //   if (e.target.value === "" || e.target.value.length !== 6) {
  //     setOtpError(true);
  //   } else {
  //     setOtpError(false);
  //   }
  //   setOtp(e.target.value);
  // };

  const handleSubmit = (e) => {};
  return (
    <>
      <div className={styles.container}>
        <HeaderWithoutProducts />

        {screen === "otp" ? (
          <></>
        ) : (
          <>
            <h1 className={styles.heading} id="transition-modal-title">
              Verify Phone Number
            </h1>
            <div className={styles.subHeading}>Enter Phone No.</div>
            <div className={styles.formGroup}>
              <TextField
                inputProps={{
                  maxLength: 10,
                }}
                sx={{
                  background: "transparent !important",
                  maxWidth: "400px",
                  width: "100%",
                  marginTop: "0px",
                }}
                label="Phone Number"
                required
                onChange={handlePhoneChange}
                value={phoneNumber}
                error={phoneError}
                helperText={phoneError && "Enter a valid phone number"}
              />

              <button
                type="submit"
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                {otpLoading ? "Please Wait..." : "Continue"}
              </button>
            </div>
            <div className={styles.bottomText}>
              An SMS may be sent. Message & data rates may apply.
            </div>
          </>
        )}
      </div>
    </>
  );
}
