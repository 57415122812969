import React from "react";
import HeaderWithProducts from "../Common/HeaderWithProducts";
import styles from "../../css/homepage.module.css";
import priorityPayment from "../../images/solution/priorityPayment.svg";
import Footer from "../Common/Footer";
import pc2 from "../../images/solution/pc2.svg";

export default function PriorityPayment() {
  return (
    <>
      <div className={styles.container}>
        <HeaderWithProducts />

        <section className={styles.mainSection}>
          <div className={styles.mainInnerSection}>
            <div className={styles.heading}>
              Stay on top of your finances with priority payments
            </div>
            <div className={styles.para}>
              Get priority part payments before the due date based on your
              spending requirements
            </div>
          </div>
          <img
            src={priorityPayment}
            alt="header"
            className={styles.headerImg}
            id={styles.balanceConfirmationImage}
          />
        </section>

        <div
          style={{
            backgroundColor: "#f2f2f273",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 4%",
            width: "100%",
          }}
        >
          <section className={styles.feature}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                loading="lazy"
                src={pc2}
                alt="balance confirmation"
                className={styles.headerImg2}
                style={{ maxWidth: "400px" }}
              />
            </div>
            <div className={styles.rightText} style={{ maxWidth: "1000px" }}>
              <div className={styles.miniHeading}>
                EQLFIN is a B2B platform that
              </div>
              <div className={styles.heading2}>
                Collects invoice payments before due date
              </div>
              <div className={styles.para2}>
                Sellers are charged a daily fee from EQLFIN, and based on their
                spending habits throughout the billing cycle are provided with
                part payments before due date to ensure that they have enough
                cash always.
                <br />
                <br />
                Our platform is currently available by invitation only to select
                companies who have been invited by their customers or suppliers.
                Please write to us at{" "}
                <a href="mailto:help@eqlfin.com"> help@eqlfin.com </a> if you
                are interested and we will get back to you at the earliest
                possible.
              </div>
            </div>
          </section>
        </div>

        <section className={styles.benefitSection}>
          <div className={styles.heading}>Key Benefits</div>
          <div className={styles.benefitInnerSection2}>
            <div>
              <div className={styles.subHeading}>
                Fee on payments before due date only
              </div>
              <div className={styles.benefitsPara}>
                Prepaid balance is only deducted when part payments are
                collected before the due date. Collections done on or after the
                due date are completely free.
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Account reconciliation</div>
              <div className={styles.benefitsPara}>
                After every new invoice or payment you receive a request for
                balance confirmation and raise any queries by uploading
                supporting documents in case of a dispute so we can notify your
                customer.
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Unlimited storage</div>
              <div className={styles.benefitsPara}>
                Storage for 500 days after the invoice is fully settled so there
                is enough time to update your accounts and check for audit
                trails of invoices.
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Download center</div>
              <div className={styles.benefitsPara}>
                All your invoices, payment details & disputes are collected in
                one place so you can download them for any accounting or tax
                filing purpose without searching in email or going through your
                bank statement looking for details.
              </div>
            </div>
          </div>
        </section>

        {/* <section className={styles.workSection}>
          <div className={styles.heading}>How EQLFIN works?</div>
          <div className={styles.imageContainer}>
            <img src={pcworks} className={styles.worksImg} alt="works" />
          </div>

        
        </section> */}

        <Footer />
      </div>
    </>
  );
}
