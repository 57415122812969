import React, { useContext, useState, useEffect, useRef } from "react";
import { auth } from "../firebaseSetup";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
  updateEmail,
} from "firebase/auth";
import { officeApiRequest } from "../components/webApp/helper";
import { balanceRequest } from "../utils";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signupStatus, setSignUpStatus] = useState(false);
  const [error, setError] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [customClaim, setCustomClaim] = useState(null);
  const [officeDetail, setOfficeDetail] = useState(null);
  const [planBuyStatus, setPlanBuyStatus] = useState(null);
  const [earlyPaymentBalance, setEarlyPaymentBalance] = useState(0);
  const [nonLoggedInUser, setNonLoggedInUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const t = useRef(null);

  const [appVerifer, setAppVerifier] = useState(null);
  const authToken = useRef(null);

  async function resend(phoneNumber) {
    return signInWithPhoneNumber(auth, phoneNumber);
  }
  async function phoneSignup(phoneNumber) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-containers",
      {
        size: "invisible",
        "expired-callback": () => {
          resend(phoneNumber);
        },
      },
      auth
    );

    recaptchaVerifier.render();

    setAppVerifier(recaptchaVerifier);

    return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const tokens = await user.getIdToken(true);

        authToken.current = tokens;

        if (tokens !== null && tokens !== "") {
          if (localStorage.getItem("officeDetail") === null) {
            const officeDetailResolver = await officeApiRequest(tokens);

            if (officeDetailResolver.hasOwnProperty("results")) {
              if (officeDetailResolver.results.length > 0) {
                setOfficeDetail(officeDetailResolver.results[0]);

                //* Setting office details in LS
                localStorage.setItem(
                  "officeDetail",
                  JSON.stringify(officeDetailResolver.results[0])
                );
              }
            }
          } else {
            //* Setting office details in LS
            const officeDetail = JSON.parse(
              localStorage.getItem("officeDetail")
            );

            setOfficeDetail(officeDetail);

            if (!sessionStorage.getItem("earlyPaymentBalance")) {
              const balanceReq = await balanceRequest(
                tokens,
                officeDetail.officeId
              );
              if (parseFloat(balanceReq.earlyPaymentBalance) > 0) {
                setPlanBuyStatus(true);
                setEarlyPaymentBalance(
                  parseFloat(balanceReq.earlyPaymentBalance)
                );

                sessionStorage.setItem(
                  "earlyPaymentBalance",
                  parseFloat(balanceReq.earlyPaymentBalance)
                );
                sessionStorage.setItem("planBuyStatus", true);
              } else {
                setPlanBuyStatus(false);
                setEarlyPaymentBalance(
                  parseFloat(balanceReq.earlyPaymentBalance)
                );
                sessionStorage.setItem(
                  "earlyPaymentBalance",
                  parseFloat(balanceReq.earlyPaymentBalance)
                );
                sessionStorage.setItem("planBuyStatus", false);
              }
            } else {
              const epBalance = JSON.parse(
                sessionStorage.getItem("earlyPaymentBalance")
              );
              if (parseFloat(epBalance) > 0) {
                setPlanBuyStatus(true);
                setEarlyPaymentBalance(parseFloat(epBalance));
              } else {
                setPlanBuyStatus(false);
                setEarlyPaymentBalance(parseFloat(epBalance));
              }
            }
          }
        }

        setToken(tokens);
        setCurrentUser(user);
        setSignUpStatus(true);
        // t.current.value = tokens;
      } else {
        console.log("shshsf");
        setSignUpStatus(false);
        setToken(null);
        setCurrentUser(null);
        setLoading(false);
        setCustomClaim(null);
      }
    });

    return () => {
      unsubscribe(); // Cleanup the event listener when the component is unmounted
    };
  }, []);

  const value = {
    error,
    signupStatus,
    loading,
    token,
    currentUser,
    customClaim,
    phoneSignup,
    updateProfile,
    updateEmail,
    resend,
    appVerifer,
    authToken,
    officeDetail,
    planBuyStatus,
    earlyPaymentBalance,
    t,
    setNonLoggedInUser,
    nonLoggedInUser,
    setUserDetails,
    userDetails,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export { AuthContext, AuthProvider };
