import React from "react";
import HeaderWithProducts from "../Common/HeaderWithProducts";
import styles from "../../css/homepage.module.css";
import balanceConfirmation from "../../images/solution/balanceConfirm.svg";
import experience from "../../images/homepage/experience.svg";
import security from "../../images/homepage/security.svg";
import handshake from "../../images/homepage/handshake.svg";
import Footer from "../Common/Footer";
import balanceConfirmation1 from "../../images/homepage/balanceConfirmation.svg";
import bcWorks from "../../images/solution/bcworks.svg";

export default function BalanceConfirmation() {
  return (
    <>
      <div className={styles.container}>
        <HeaderWithProducts />

        <section className={styles.mainSection}>
          <div className={styles.mainInnerSection}>
            <div className={styles.heading}>Get Balance Statements</div>
            <div className={styles.para}>
              You get balance statements directly from your clients books
              through EQLFIN. This streamlined process eliminates the need for
              manual reconciliation, further reduces errors and delayed payments
            </div>
          </div>
          <img
            src={balanceConfirmation}
            alt="header"
            className={styles.headerImg}
            id={styles.balanceConfirmationImage}
          />
        </section>

        <div
          style={{
            backgroundColor: "#f2f2f273",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 4%",
            width: "100%",
          }}
        >
          <section className={styles.feature}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                loading="lazy"
                src={balanceConfirmation1}
                alt="balance confirmation"
                className={styles.headerImg2}
                style={{ maxWidth: "400px" }}
              />
            </div>
            <div className={styles.rightText} style={{ maxWidth: "1000px" }}>
              <div className={styles.heading2}>
                EQLFIN is an <br />
                Online balance confirmation service
              </div>
              <div className={styles.para2}>
                for accounting teams to get balance confirmations from thousands
                of vendors after every transaction effortlessly. This avoids
                last minute audit & compliance issues. Companies can add as many
                vendors as they want on EQLFIN to get balance confirmation.
                <br />
                <br />
                Our platform is currently available by invitation only to select
                companies who have been invited by their customers or suppliers.
                Please write to us at{" "}
                <a href="mailto:help@eqlfin.com"> help@eqlfin.com </a> if you
                are interested and we will get back to you at the earliest
                possible.
              </div>
            </div>
          </section>
        </div>

        <section className={styles.benefitSection}>
          <div className={styles.heading}>Key Benefits</div>
          <div className={styles.benefitInnerSection2}>
            <div>
              <div className={styles.subHeading}>
                Bulk upload data from any system
              </div>
              <div className={styles.benefitsPara}>
                You can use our secure APIs for direct upload of invoice &
                payment updates into our platform. EQLFIN will provide ready
                integration tools and continuous support.
              </div>
            </div>

            <div>
              <div className={styles.subHeading}>Send XL attachments</div>
              <div className={styles.benefitsPara}>
                Admin users can upload data from their authorised email ids by
                just attaching excel sheets. All emails are scanned for security
                and the sender is verified before data is uploaded.
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>
                Register for whatsapp alerts
              </div>
              <div className={styles.benefitsPara}>
                Register for whatsapp alerts when there is any change or update
                on payments & invoices. Users will have to ‘ALLOW’ whatsapps
                from our number +91-8076438688
              </div>
            </div>
            <div>
              <div className={styles.subHeading}>Pricing confidentiality</div>
              <div className={styles.benefitsPara}>
                We do not ask for or use any item-level data including item
                codes, specifications, rate, quantity, or even HSN / SAC codes.
                Our system only works on invoice metadata including vendor GSTN,
                invoice number, total amount, GST amount, TDS amount, and
                payment data.
              </div>
            </div>
          </div>
        </section>

        {/* <section className={styles.workSection}>
          <div className={styles.heading}>How EQLFIN works?</div>
          <div className={styles.imageContainer}>
            <img src={bcWorks} className={styles.worksImg} alt="works" />
          </div>

     
        </section> */}

        <Footer />
      </div>
    </>
  );
}
