import React, { useEffect, useMemo, useState } from "react";
import styles from "../../css/balanceConfirmation/balanceConfirmation.module.css";
import { useAuth } from "../../Contexts/AuthContext";
import {
  NumInWords,
  acquisitonApiCall,
  balanceConfirmApiCall,
  balanceConfirmation,
  disputeApiCall,
  leadCapture,
  officeDetailExtract,
  pendingAmountCount,
} from "../../utils";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Modal } from "@mui/material";

import moment from "moment";

import ConfirmSuccess from "./ConfirmSuccess";
import caution from "../../images/commons/caution.svg";
import EarlyPaymentRequest from "./EarlyPaymentRequest";
import Loader from "../Common/Loader";
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function BalanceConfirm() {
  const navigate = useNavigate();

  const { state } = useLocation();

  const { token, officeDetail, currentUser, setUserDetails, userDetails } =
    useAuth();
  const [statements, setStatements] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clientContactDetails, setClientContactDetails] = useState(null);
  const [sellerContactDetails, setSellerContactDetails] = useState(null);
  const [currentState, setCurrentState] = useState("loader");
  const [statementActivityId, setStatementActivityId] = useState("");

  const [confirmationData, setConfirmationData] = useState(null);
  const [queryParams] = useSearchParams();

  const [customerId, setCustomerId] = useState("");

  const [errorState, setErrorState] = useState(false);
  const [message, setMessage] = useState("");
  const [statementExist, setStatementExist] = useState(false);

  const handleMismatchClose = async (e) => {
    e.preventDefault();

    const disputeApiCallResult = await disputeApiCall({
      token: token,
      statements: state.statements,
    });
    if (disputeApiCallResult) {
      //
    }

    setDialogOpen(false);
  };
  const source = queryParams?.get("source");
  const campaign = queryParams?.get("campaign");
  const id = queryParams?.get("id");
  const campaignId = queryParams?.get("campaignId");
  const phoneNumber = queryParams?.get("phoneNumber");

  const [officeExtractInfo, setOfficeExtractInfo] = useState(null);

  const [buttonState, setButtonState] = useState(false);
  useEffect(() => {
    if (source !== null && campaign !== null && id !== null) {
      window.localStorage.setItem("customerActivityId", id);
      window.localStorage.setItem("initialUrl", "balance-confirm");
      window.localStorage.setItem(
        "sourceDetails",
        JSON.stringify({
          campaign: campaign,
          source: source,
          id: id,
          campaignId: campaignId,
        })
      );
      window.localStorage.setItem("regNumber", phoneNumber);
    }
    if (state?.success) {
      setStatementExist(false);
    }

    console.log(state);
  }, []);

  useEffect(() => {
    const verify = window.localStorage.getItem("_grecaptcha");
    if (verify) {
      // do not redirect

      const dataFetch = async () => {
        if (token !== null && token !== "") {
          //
          const customerActivityId =
            window.localStorage.getItem("customerActivityId");

          if (customerActivityId !== null && customerActivityId !== "") {
            //

            setCustomerId(customerActivityId);

            if (window.sessionStorage.getItem("statements") != null) {
              if (
                window.sessionStorage.getItem("balanceConfirmation") === null
              ) {
                const statementData = JSON.parse(
                  window.sessionStorage.getItem("statements")
                );
                setStatements(statementData.data);
                setStatementActivityId(statementData.activityId);
                setCurrentState("showTable");
              } else {
                const confirmDetails = JSON.parse(
                  window.localStorage.getItem("balanceConfirmDetails")
                  // window.localStorage.getItem("officeDetail")
                );

                setConfirmationData(confirmDetails);
                setCurrentState("successMessage");
              }
            } else {
              const balanceConfirmationStatement = await balanceConfirmation(
                token,
                customerActivityId,
                currentUser.phoneNumber
              );
              const sourceDetails = JSON.parse(
                window.localStorage.getItem("sourceDetails")
              );

              window.sessionStorage.setItem(
                "customerActivityId",
                customerActivityId
              );

              if (balanceConfirmationStatement.status) {
                setErrorState(false);
                setStatementExist(true);
                if (balanceConfirmationStatement.data.length > 0) {
                  // show the balance statement
                  console.log(balanceConfirmationStatement.data[0]);

                  setStatements(
                    Object.values(
                      balanceConfirmationStatement.data[0].statementData
                    )
                  );

                  setStatementActivityId(
                    balanceConfirmationStatement.data[0].activityId
                  );

                  setCurrentState("showTable");

                  window.sessionStorage.setItem(
                    "statements",
                    JSON.stringify({
                      activityId:
                        balanceConfirmationStatement.data[0].activityId,
                      data: Object.values(
                        balanceConfirmationStatement.data[0].statementData
                      ),
                    })
                  );
                } else {
                  // check in the localStorage any data is available or not

                  const confirmDetails = JSON.parse(
                    window.localStorage.getItem("balanceConfirmDetails")
                    // window.localStorage.getItem("officeDetail")
                  );

                  setConfirmationData(confirmDetails);
                  setCurrentState("successMessage");
                }
              } else {
                console.log("inside the error");
                const resp = await officeDetailExtract(
                  token,
                  customerActivityId
                );
                console.log(resp);
                if (resp.success) {
                  setOfficeExtractInfo(resp);

                  if (resp.statement.length > 0) {
                    setStatements(
                      Object.values(resp.statement[0].Logs.invoices)
                    );
                  }
                }
                setErrorState(true);
                setMessage(balanceConfirmationStatement.message);
                setCurrentState("errorCase");
              }

              if (sourceDetails !== null) {
                const body = {
                  source: customerActivityId,
                  medium: sourceDetails.source,
                  campaign: sourceDetails.campaign,
                  office:
                    statements.length > 0
                      ? statements[0].Logs.sellerOffice
                      : officeDetail.office,
                  campaignId: sourceDetails.campaignId,
                };

                await acquisitonApiCall(body, token);
                window.localStorage.removeItem("sourceDetails");
              }
            }
          } else {
            if (officeDetail !== null && officeDetail !== "") {
              setCurrentState("successMessage");
            } else {
              // add the error message

              // check the details exist or not if
              const details = JSON.parse(
                window.localStorage.getItem("details")
              );

              if (details === null) {
                setCurrentState("userDetails");
              } else {
                setCurrentState("userDetailsExist");
              }
            }
          }
        } else {
          //

          await delay(2000);
        }
      };
      dataFetch().then().catch(console.error);
    } else {
      if (source !== null && campaign !== null && id !== null) {
        // send an api request for adding in the Logs
        const body = {
          name: "",
          phoneNumber: "",
          email: "",
          gstNumber: "",
          bookingTime: "",
          timezone: "Asia/Kolkata",
          source: {
            source: campaignId,
            medium: source,
            campaign: campaign,
            customerId: id,
          },
          origin: "balance-confirmation",
          requestedUrl: window.location.href,
          referer: document.referrer || "",
          comment: `balance-confirm page visited using this customer activityId ${id}`,
          type: "balance-confirm link click",
        };

        leadCapture(token, body)
          .then()
          .catch((err) => console.error(err));

        navigate(
          `/login?source=${source}&campaign=${campaign}&id=${id}&campaignId=${campaignId}&phoneNumber=${phoneNumber}`
        );
      } else {
        navigate("/login");
      }
    }
  }, [token]);

  const pendingAmountDetails = useMemo(
    () => pendingAmountCount(statements),
    [statements]
  );
  useEffect(() => {
    if (statements.length > 0) {
      console.log("hello", userDetails);
      setUserDetails(pendingAmountDetails);
      console.log(pendingAmountDetails);
    }
  }, [pendingAmountDetails]);

  const handleYesButton = async (e) => {
    e.preventDefault();

    setButtonState(true);

    const balanceConfirmResult = await balanceConfirmApiCall({
      token: token,
      activityId: statementActivityId,
      customerActivityId: customerId,
    });
    setCurrentState("loader");

    if (balanceConfirmResult.success) {
      const body = {
        confirmAmount: pendingAmountDetails.pendingAmount,
        confirmTimestamp: Date.now(),

        office: officeDetail !== null ? officeDetail.office : "",
        phoneNumber: currentUser.phoneNumber,
        name: officeDetail !== null ? officeDetail.name : "",
      };
      setConfirmationData(body);
      // setDetails(body);

      window.localStorage.setItem(
        "balanceConfirmDetails",
        JSON.stringify(body)
      );
      window.sessionStorage.setItem("balanceConfirmation", true);

      // setLoading(false);
      setButtonState(false);
      setCurrentState("successMessage");
    } else {
      setButtonState(false);
      setCurrentState("showTable");
    }
  };
  // how to use useMemo

  const toRenderComponent = (comp) => {
    switch (comp) {
      case "loader":
        return (
          <>
            <Loader />
          </>
        );

      case "showTable":
        return (
          <>
            <div>
              <EarlyPaymentRequest details={pendingAmountDetails} />
            </div>
            <h3 className={styles.balanceConfirmationHead}>
              Balance Confirmation
            </h3>

            <p className={styles.balanceConfirmationPara}>
              This is to certify that in accordance with the books of accounts
              of &nbsp;
              {statements.length > 0 ? statements[0].Logs.customerOffice : ""},
              the outstanding balance as of &nbsp;
              {moment().format("DD-MM-YYYY")} is given below:
            </p>
            <br />
            <section>
              <table
                className={styles.balanceConfirmTable}
                style={{ width: "100%" }}
              >
                <thead>
                  <tr className={styles.tableHeadRow}>
                    <th
                      className={styles.tableHead}
                      style={{ borderLeft: "none" }}
                    >
                      S.NO.
                    </th>
                    <th className={styles.tableHead}>Invoice No.</th>
                    <th className={styles.tableHead}>Due Date</th>
                    <th
                      className={styles.tableHead}
                      style={{ borderRight: "none" }}
                    >
                      Amount (₹)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statements.map((statement, index) => {
                    const key = statement.attachment.name.value + index;

                    return (
                      <>
                        <tr className="tableRow" key={key}>
                          <td
                            className={styles.tableContent}
                            style={{ borderRight: "1px solid #4f4f4f" }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className={styles.tableContent}
                            style={{ borderRight: "1px solid #4f4f4f" }}
                          >
                            <Link
                              to={`/balance-confirmation/invoice?invoice=${statement.attachment.name.value}`}
                              state={{
                                ...statement,
                                ...clientContactDetails,
                                ...sellerContactDetails,
                              }}
                            >
                              {statement.attachment.name.value}
                            </Link>
                          </td>
                          <td
                            className={styles.tableContent}
                            style={{ borderRight: "1px solid #4f4f4f" }}
                          >
                            {moment(statement.Logs.dueDateTimestamp).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td
                            className={styles.tableContent}
                            style={{ borderRight: "1px solid #4f4f4f" }}
                          >
                            {parseFloat(statement.Logs.pendingAmount)}
                          </td>
                        </tr>
                      </>
                    );
                  })}

                  <tr>
                    <td
                      className={styles.tableContent}
                      style={{ border: "1px solid #4f4f4f" }}
                      colSpan={3}
                    >
                      Total Balance
                    </td>
                    <td
                      className={styles.tableContent}
                      style={{
                        border: "1px solid #4f4f4f",
                        textAlign: "right",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>
                        {pendingAmountDetails.pendingAmount.toLocaleString(
                          "en-IN"
                        )}{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.tableContent} colSpan={4}>
                      Amount in Words :
                      <span style={{ fontWeight: "600" }}>
                        {NumInWords(pendingAmountDetails.pendingAmount)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{
                  position: "absolute",
                  marginTop:"10%",
                  transform: "translate(0px, 0px)",
                  maxWidth: "400px",
                  width: "100%",
                }}
              >
                {/* <h3 className={styles.balanceConfirmFooter}>
                  Are you authorized to confirm the balance on the behalf of
                  <b>
                    {" "}
                    {statements.length > 0
                      ? statements[0].Logs.sellerOffice
                      : ""}{" "}
                    ?
                  </b>
                </h3> */}
                <p className={styles.declarationContents}>
                  I hereby confirm the balance on behalf of{" "}
                  <b>{officeDetail !== null ? officeDetail.office : ""}</b>
                </p>

                <div
                  className={styles.balanceConfirmButtonDiv}
                  style={{ justifyContent: "space-between",marginRight:"5%" }}
                >
                  <Button
                    className={styles.balanceConfirmButton}
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                    variant="contained"
                    style={{ color: "#1904FF", background: "#fff" }}
                  >
                    DISPUTE
                  </Button>

                  <Button
                    variant="contained"
                    className={styles.balanceConfirmButton}
                    onClick={handleYesButton}
                    disabled={buttonState}
                  >
                    CONFIRM
                  </Button>
                </div>
                <p className={styles.declarationFooter}>
                  By Tapping CONFIRM you are confirming that this is the balance
                  as per your record
                </p>
                <p className={styles.declarationFooter}>
                  By Tapping DISPUTE you are reporting balance mismatch
                </p>
              </div>
              <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    background: "white",
                    maxWidth: "350px",
                    gap: "10px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    padding: "20px",
                    transform: "translate(-50%, -50%)",
                    outline: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <img src={caution} alt="caution" width={"30px"} />
                    <p style={{ marginTop: "0px" }}>
                      Do you want to report any mismatch in balance details?
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <button
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #1904FF",
                        borderRadius: "7px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#1904FF",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setDialogOpen(false)}
                    >
                      No
                    </button>
                    <button
                      style={{
                        background: "#1904ff",
                        border: "1px solid #1904FF",
                        borderRadius: "7px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "white",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleMismatchClose}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </Modal>
            </section>
          </>
        );

      case "successMessage":
        return (
          <>
            <ConfirmSuccess details={confirmationData} />
          </>
        );

      case "userDetailsExist":
        return (
          <>
            <section>
              <p>Our Representative will Contact You Shortly</p>
            </section>
          </>
        );

      case "errorCase":
        return (
          <>
            {officeExtractInfo !== null ? (
              <div>
                <EarlyPaymentRequest details={pendingAmountDetails} />
              </div>
            ) : (
              ""
            )}
            <section>
              <p>{message}</p>
            </section>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <section className={styles.balanceConfirmationSection}>
        <div className={styles.balanceConfirmHeadDiv}>
          <div className={styles.contentDiv}>
            {toRenderComponent(currentState)}
          </div>
        </div>
      </section>
    </>
  );
}
