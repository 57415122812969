import { CircularProgress } from "@mui/material";

const PageLoadingBox = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <h1>Please Wait...</h1> <br />
    </div>
  );
};
export default PageLoadingBox;
