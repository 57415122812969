import "../../css/website/webapp.css";
import React, { useState, useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextField } from "@mui/material";
import moment from "moment";
import Header from "../campaigns/Header";
import linelabel from "../../images/linelabel.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../Contexts/AuthContext";
import AdCampaignHeaderLogin from "./AdCampaignHeaderLogin";
import { dueDateChangeDemand, pendingDemandRequestCustomer } from "./helper";
export default function PayLater() {
  const { state } = useLocation();
  const history = useNavigate();

  const { token, currentUser, authToken } = useAuth();
  const [dueDemands, setDueDemands] = useState([]);
  const [nonDueDemands, setNonDueDemands] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const today = moment().format("YYYY-MM-DD");
  const tomorrowTime = moment().add(2, "days").format("YYYY-MM-DD");
  const theme = useTheme();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [demandId, setDemandId] = useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const verify = localStorage.getItem("_grecaptcha");
    if (verify) {
      if (state === null) {
        return history("/dashboard");
      }
      if (authToken !== null) {
        console.log(token);
        async function resolver() {
          let pendingDemandRequestResolver = await pendingDemandRequestCustomer(
            authToken.current,
            state.officeId
          );

          const tomorrowTime = moment().add(1, "days").valueOf("x");

          if (pendingDemandRequestResolver !== undefined) {
            const pendingDemandResolver = pendingDemandRequestResolver.filter(
              (vals) =>
                vals.documentNumber !== null && vals.documentNumber !== ""
            );
            console.log(pendingDemandRequestResolver);

            const before = pendingDemandResolver.filter(
              (vals) => vals.dueDate <= tomorrowTime
            );
            const after = pendingDemandResolver.filter(
              (vals) => vals.dueDate >= Date.now()
            );
            const minAmount = before.reduce(
              (accum, item) => accum + item.pendingAmount,
              0
            );
            setDueAmount(minAmount);
            // tomorrow tak invoice upar wale section
            //

            // sorting based on time
            after.sort((a, b) => a.dueDate - b.dueDate);
            const dateBasedGroup = {};

            after.forEach((vals) => {
              const dueDate = moment(vals.dueDate).format("DD-MM-YYYY");

              if (dateBasedGroup[dueDate] !== undefined) {
                const demandData = dateBasedGroup[dueDate];
                demandData.push(vals);
                dateBasedGroup[dueDate] = demandData;
              } else {
                dateBasedGroup[dueDate] = [vals];
              }
            });

            setDueDemands(before);
            setNonDueDemands(after);
          }
        }
        resolver()
          .then()
          .catch((err) => {
            // setInformationFetch(false);
            console.error(err);
          });
      }
    }
  }, [currentUser]);

  const dueDateChange = async (e, demand) => {
    const timestamp = moment(e.target.value).format("x");
    const body = {
      dueDateTimestamp: Number(timestamp),
      demandId: demand.demandId,
    };

    const result = await dueDateChangeDemand(body, token);

    if (result.success) {
      const updatedDemand = dueDemands.find(
        (vals) => vals.demandId === demandId
      );
      updatedDemand.dueDate = timestamp;
      nonDueDemands.push(updatedDemand);
      nonDueDemands.sort((a, b) => a.dueDate - b.dueDate);
      const dateBasedGroup = {};
      nonDueDemands.forEach((vals) => {
        const dueDate = moment(vals.dueDate).format("DD-MM-YYYY");

        if (dateBasedGroup[dueDate] !== undefined) {
          const demandData = dateBasedGroup[dueDate];
          demandData.push(vals);
          dateBasedGroup[dueDate] = demandData;
        } else {
          dateBasedGroup[dueDate] = [vals];
        }
      });

      setNonDueDemands(nonDueDemands);
      setDemandId("");
      setCalendarOpen(false);
    } else {
      setDemandId("");
      setCalendarOpen(false);
    }
  };

  const handleDialogPopup = (e, demand) => {
    setCalendarOpen(true);

    setDemandId(demand.demandId);
  };
  const handleClose = () => {
    setDemandId("");
    setCalendarOpen(false);
  };

  const dueDateChangeFunction = async (e) => {
    const timestamp = moment(e.target.value).format("x");
    const body = {
      dueDateTimestamp: Number(timestamp),
      demandId: demandId,
    };

    const result = await dueDateChangeDemand(body, token);

    if (result.success) {
      const updatedDemandsDue = dueDemands.filter(
        (vals) => vals.demandId !== demandId
      );
      const updatedDemand = dueDemands.find(
        (vals) => vals.demandId === demandId
      );
      updatedDemand.dueDate = Number(timestamp);

      nonDueDemands.push(updatedDemand);
      nonDueDemands.sort((a, b) => a.dueDate - b.dueDate);

      const dateBasedGroup = {};
      nonDueDemands.forEach((vals) => {
        const dueDate = moment(vals.dueDate).format("DD-MM-YYYY");

        if (dateBasedGroup[dueDate] !== undefined) {
          const demandData = dateBasedGroup[dueDate];
          demandData.push(vals);
          dateBasedGroup[dueDate] = demandData;
        } else {
          dateBasedGroup[dueDate] = [vals];
        }
      });
      setDueDemands(updatedDemandsDue);
      setNonDueDemands(nonDueDemands);
      setDemandId("");
      setCalendarOpen(false);
    } else {
      setDemandId("");
      setCalendarOpen(false);
    }
  };
  return (
    <>
      {state !== null ? (
        <>
          <AdCampaignHeaderLogin />
          <div className="amountheading">
            <a className="link" href="https://eqlfin.com/home">
              <ArrowBackIcon fontSize="large"></ArrowBackIcon>
            </a>
            <h1 className="mindue">Min. Amount Due: ₹{dueAmount.toFixed(2)}</h1>
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={calendarOpen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                <TextField
                  id="date"
                  className="date"
                  // label="Due Date"
                  type="date"
                  defaultValue={moment(tomorrowTime).format("YYYY-MM-DD")}
                  sx={{ width: 320 }}
                  onChange={(e) => dueDateChangeFunction(e)}
                  inputProps={{
                    // only needs the first 16 characters in the date string
                    min: tomorrowTime,
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                style={{ width: "100px" }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  style={{
                    fontSize: "42px",
                    width: "100%",
                    background: "#F5F4FF",
                  }}
                >
                  <TableRow>
                    <TableCell align="left" colSpan={6}>
                      Vendor Name
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      Invoice Number
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      Pending Amount
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      Due Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dueDemands.map((demand) => {
                    return (
                      <>
                        <TableRow key={demand.demandId}>
                          <TableCell align="left" colSpan={6}>
                            {demand.billingBranch.replace("Branch", "")}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            {demand.documentNumber}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            ₹{demand.pendingAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            <Button
                              variant="contained"
                              onClick={(e) => handleDialogPopup(e, demand)}
                              style={{ width: "200px", height: "45px" }}
                            >
                              Pay Later
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                  <TableRow>
                    {" "}
                    <TableCell align="left" colSpan={12}>
                      <img src={linelabel} alt="logo" />
                    </TableCell>{" "}
                  </TableRow>

                  {nonDueDemands.map((demand) => {
                    // const demand = nonDueDemands[date]

                    return (
                      <>
                        <TableRow key={demand.demandId}>
                          <TableCell align="left" colSpan={6}>
                            {demand.billingBranch.replace("Branch", "")}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            {demand.documentNumber}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            ₹{demand.pendingAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="left" colSpan={2}>
                            <TextField
                              id="date"
                              className="date"
                              // label="Due Date"
                              type="date"
                              defaultValue={moment(demand.dueDate).format(
                                "YYYY-MM-DD"
                              )}
                              sx={{ width: 220 }}
                              onChange={(e) => dueDateChange(e, demand)}
                              inputProps={{
                                // only needs the first 16 characters in the date string
                                min: tomorrowTime,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* {
      state.filter(vals =>vals.due)
    } */}
        </>
      ) : (
        <>
          <AdCampaignHeaderLogin />
          <h2 className="noInoiceAvailable">No Invoice Available</h2>
        </>
      )}
    </>
  );
}
