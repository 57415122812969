import React from "react";
import Box from "@mui/material/Box";
import logo from "../../images/logo.svg";
import Grid from "@mui/material/Grid";
import styles from "../../css/adCampaignHeader.module.css";
export default function AdCampaignHeaderLogin() {
  return (
    <>
      <section className={styles.adCampaignHeader}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}></Grid>
            <Grid item xs={12} sm={4} md={4}>
              <a href="https://eqlfin.com/home">
                <img src={logo} className={styles.adCampaignLogo} alt="logo" />
              </a>
            </Grid>
            <Grid item xs={12} sm={4} md={4}></Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
}
