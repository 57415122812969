import React, { useEffect, useState } from "react";
import EarlyPaymentTemplate from "../Earlypayment/EarlyPaymentTemplate";
import styles from "../../css/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { trackAuth } from "../../firebaseSetupTrack";
import { DeleteAccountApiCall, phoneNumberRegex } from "../../utils";
import { Button, TextField } from "@mui/material";
import PhoneNumberField from "../Common/PhoneNumberField";
import { useTrackAuth } from "../../Contexts/TrackContext";

const DeleteAccount = () => {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [resendButton, setResendButton] = useState(true);
  const [seconds, setSeconds] = useState(20);
  const [otpLoading, setOtpLoading] = useState(false);
  const [screen, setScreen] = useState("login");
  const [loading, setLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  //errors
  const [phoneError, setPhoneError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const { token, currentUser } = useTrackAuth();

  useEffect(() => {
    const verify = window.localStorage.getItem("_grecaptcha");

    if (verify) {
      if (token !== null && token !== "") {
        setScreen("delete");
      }
    } else {
      setScreen("login");
    }
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        setResendButton(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const resendButtonClick = async (e) => {
    e.preventDefault();
    setResendButton(false);

    await signInWithPhoneNumber(
      trackAuth,
      `+91${phoneNumber}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((err) => console.log("signin function error: ", err));
    setSeconds(20);
  };

  const otpTrigger = async () => {
    // e.preventDefault();
    setResendButton(false);

    if (phoneNumberRegex.test(phoneNumber)) {
      setOtpLoading(true);
      setPhoneError(false);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-containers",
        {
          size: "invisible",
          "expired-callback": () => {},
        },
        trackAuth
      );

      window.recaptchaVerifier.render();
      await signInWithPhoneNumber(
        trackAuth,
        `+91${phoneNumber}`,
        window.recaptchaVerifier
      )
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setOtpLoading(false);
        })
        .catch((err) => {
          window.recaptchaVerifier
            .render()
            .then((widgetId) => window.grecaptcha.reset(widgetId));
        });
      setLoading(false);
      setScreen("otpScreen");
    } else {
      if (phoneNumber === "" || !phoneNumberRegex.test(phoneNumber)) {
        setPhoneError(true);
      }
    }
    setLoading(false);
    setSeconds(20);
  };

  const otpVerify = async (e) => {
    e.preventDefault();

    if (otp.length === 6) {
      setOtpError(false);
      let confirmationResult = window.confirmationResult;
      let otpResult = "";
      try {
        setLoading(true);
        otpResult = await confirmationResult.confirm(otp);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOtpError(true);
      }
      const otpverificationResult = await otpResult;
      if (otpverificationResult) {
        //show component here
        setScreen("delete");
      } else {
        setOtpError(true);
      }
    } else {
      setOtpError(true);
    }
  };

  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    if (e.target.value === "" || e.target.value.length !== 6) {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
    setOtp(e.target.value);
  };

  const handleSubmit = () => {
    if (!phoneError) {
      otpTrigger();
    }
  };

  const handleDeleteSubmit = async (e) => {
    setLoading(true);
    console.log(currentUser.phoneNumber);

    const deleteAccountStatus = await DeleteAccountApiCall({
      token: token,
      phoneNumber: currentUser.phoneNumber,
    });

    if (deleteAccountStatus.success) {
      setDeleteSuccess(true);
      setLoading(false);
    } else {
      setDeleteSuccess(false);
    }
  };

  const componentToRender = () => {
    switch (screen) {
      case "login":
        return (
          <>
            <h1 className={styles.heading} id="transition-modal-title">
              Verify Phone Number
            </h1>
            <div className={styles.subHeading}>Enter Phone No.</div>
            <div className={styles.formGroup}>
              <PhoneNumberField
                sx={{
                  background: "transparent !important",
                  maxWidth: "400px",
                  width: "100%",
                  marginTop: "0px",
                }}
                label="Phone Number"
                required
                handlePhoneChange={handlePhoneChange}
                phoneNumber={phoneNumber}
                phoneError={phoneError}
              />

              <button
                type="submit"
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                {otpLoading ? "Please Wait..." : "Continue"}
              </button>
            </div>
            <div className={styles.bottomText}>
              An SMS may be sent. Message & data rates may apply.
            </div>
          </>
        );

      case "otpScreen":
        return (
          <>
            <h1 className={styles.heading} id="transition-modal-title">
              Verify Phone Number
            </h1>

            <div className={styles.subHeading}>
              Enter the 6-digit code we sent to
              <span style={{ color: "#1904ff" }}>+91-{phoneNumber}</span>
            </div>
            <div className={styles.subHeading} style={{ marginTop: "10px" }}>
              Enter OTP
            </div>
            <div className={styles.formGroup}>
              <TextField
                inputProps={{
                  maxLength: 6,
                }}
                sx={{
                  background: "transparent !important",
                  width: "100%",
                  maxWidth: "400px",
                }}
                label="OTP"
                required
                onChange={handleOtpChange}
                value={otp}
                error={otpError}
                helperText={otpError && "Enter a valid OTP"}
              />
            </div>
            <div className={styles.buttonGroup}>
              <div
                className={styles.backButton}
                onClick={() => setScreen("login")}
              >
                Back
              </div>
              <button className={styles.submitButton} onClick={otpVerify}>
                {loading ? "Please Wait... " : "Verify"}
              </button>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              {!resendButton ? (
                <div>
                  Resend Code in 00:{seconds < 10 ? `0${seconds}` : seconds}{" "}
                </div>
              ) : (
                <Button onClick={resendButtonClick}>Resend</Button>
              )}
            </div>
            <div
              style={{
                margin: "20px 10px 0px 10px",
                textAlign: "center",
                maxWidth: "400px",
              }}
              className={styles.bottomText}
            >
              By tapping Verify, you are indicating that you accept our{" "}
              <Link to="/security-privacy" target="_blank">
                <span style={{ color: "#1904ff" }}> Terms of Service </span>
              </Link>
              and
              <Link to="/security-privacy" target="_blank">
                <span style={{ color: "#1904ff" }}> Privacy Policy.</span>
              </Link>
            </div>
          </>
        );

      case "delete":
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {deleteSuccess ? (
                <div>
                  <b style={{ fontSize: "20px" }}>Your account is deleted!</b>
                </div>
              ) : (
                <>
                  Are you sure you want to delete your account?
                  <div className={styles.buttonGroup}>
                    <div
                      className={styles.backButton}
                      onClick={() => {
                        setScreen("login");
                        setPhoneNumber("");
                        setOtp("");
                      }}
                    >
                      Back
                    </div>
                    <button
                      className={styles.submitButton}
                      onClick={handleDeleteSubmit}
                    >
                      {loading ? "Please Wait... " : "Delete Account"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <EarlyPaymentTemplate showHeader={true} showProfile={false}>
      <div className={styles.container}>
        <section className={styles.mainSection}>{componentToRender()}</section>

        <div id="recaptcha-containers"></div>
        <div id="recaptcha-containers-resend"></div>
      </div>
    </EarlyPaymentTemplate>
  );
};

export default DeleteAccount;
