import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, CircularProgress, Modal } from "@mui/material";
import { balanceConfirmApiCall, disputeApiCall } from "../../utils";
import { useAuth } from "../../Contexts/AuthContext";
import styles from "../../css/balanceConfirmation/balanceConfirmation.module.css";
import moment from "moment";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import caution from "../../images/commons/caution.svg";

import ConfirmSuccess from "./ConfirmSuccess";
export default function Declaration() {
  const { state } = useLocation();
  // console.log(state);

  const navigate = useNavigate();

  const { token, officeDetail, currentUser } = useAuth();

  const [currentState, setCurrentState] = useState("declare");

  const [buttonState, setButtonState] = useState(false);

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const balanceConfirm = async (e) => {
    e.preventDefault();
    setButtonState(true);

    setLoading(true);

    const balanceConfirmResult = await balanceConfirmApiCall({
      token: token,
      activityId: state.statementActivityId,
      customerActivityId: state.customerActivityId,
    });

    if (balanceConfirmResult.success) {
      const body = {
        confirmAmount: state.pendingAmountDetails.pendingAmount,
        confirmTimestamp: Date.now(),

        office: officeDetail !== null ? officeDetail.office : "",
        phoneNumber: currentUser.phoneNumber,
        name: officeDetail !== null ? officeDetail.name : "",
      };
      setDetails(body);

      window.localStorage.setItem(
        "balanceConfirmDetails",
        JSON.stringify(body)
      );
      setLoading(false);
      setButtonState(false);
      setCurrentState("success");
    } else {
      setButtonState(false);
    }
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    navigate("/balance-confirmation");
  };

  const handleMismatchClose = async (e) => {
    e.preventDefault();

    const disputeApiCallResult = await disputeApiCall({
      token: token,
      statements: state.statements,
    });
    if (disputeApiCallResult) {
      //
    }

    setDialogOpen(false);
  };

  const toRenderComponent = (comp) => {
    switch (comp) {
      case "loader":
        return (
          <>
            <CircularProgress
              style={{
                textAlign: "center",
                display: "block",
                margin: "20% auto",
              }}
            />
          </>
        );

      case "declare":
        return (
          <>
            <div className={styles.declarationHeader}>
              <KeyboardBackspaceIcon onClick={handleBackButton} />
              <p>Declaration</p>
            </div>

            <div className={styles.declarationContent}>
              <div style={{ marginTop: "30px" }}>
                <p className={styles.declarationContents}>
                  I hereby agree that I am authorised to confirm the balance on
                  behalf of{" "}
                  <b>{officeDetail !== null ? officeDetail.office : ""}</b>
                </p>
              </div>
              <div style={{ marginTop: "30px" }}>
                <p className={styles.declarationContents}>
                  Date : <b>{moment().format("DD-MM-YYYY")}</b>
                </p>

                <p className={styles.declarationContents}>
                  Place : <b>{}</b>
                </p>
              </div>
              <div style={{ marginTop: "30px" }}>
                <p className={styles.declarationContents}>
                  <b>{officeDetail !== null ? officeDetail.name : ""}</b>
                </p>

                <p className={styles.declarationContents}>Auth. signatory</p>
              </div>
            </div>

            <div className={styles.confirmBalanceButtonDiv}>
              <Button
                onClick={balanceConfirm}
                variant="contained"
                className={styles.confirmBalanceButton}
                disabled={buttonState}
              >
                {loading ? "Loading" : "CONFIRM BALANCE"}
              </Button>
              <p className={styles.declarationFooter}>
                By Tapping CONFIRM BALANCE you are confirming that this is the
                balance as per your record
              </p>

              <p className={styles.declarationFooter}>
                <span
                  style={{
                    color: "#1904ff",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  Click here
                </span>{" "}
                &nbsp; to report any balance mismatch
              </p>

              <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    background: "white",
                    maxWidth: "350px",
                    gap: "10px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    padding: "20px",
                    transform: "translate(-50%, -50%)",
                    outline: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <img src={caution} alt="caution" width={"30px"} />
                    <p style={{ marginTop: "0px" }}>
                      Do you want to report any mismatch in balance details?
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <button
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #1904FF",
                        borderRadius: "7px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#1904FF",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setDialogOpen(false)}
                    >
                      No
                    </button>
                    <button
                      style={{
                        background: "#1904ff",
                        border: "1px solid #1904FF",
                        borderRadius: "7px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "white",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleMismatchClose}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </>
        );

      case "success":
        return (
          <>
            <ConfirmSuccess details={details} />
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <section className={styles.balanceConfirmationSection}>
        <div className={styles.balanceConfirmHeadDiv}>
          <div className={styles.contentDiv}>
            {toRenderComponent(currentState)}
          </div>
        </div>
      </section>
    </>
  );
}
