import "../../css/website/security.css";
import React from "react";
import logo from "../../images/logo.svg";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer";

export default function Security() {
  return (
    <>
      <Box className="LogoHead">
        <Link to="/">
          <img src={logo} className="campaignLogo" alt="logo" height="70px" />
        </Link>
      </Box>
      {/* <section className="securitySection"> */}
      {/* <div className="aboutSectionDiv"> */}
      <h1 className="aboutHead">Privacy & Security</h1>
      {/* </div> */}
      {/* </section> */}
      <br />
      <br />
      <section id="privacy-policy">
        <div className="content-max-widthSecurity">
          <h2 className="privacy">Privacy Policy</h2>
          <hr />
          <br />
          <p className="textPara">
            Welcome to the Privacy Policy & Terms of Use ("<b>Terms</b>") of{" "}
            <b>
              EQLFIN. EQLFIN is wholly owned by Growthfile Analytics Private
              Limited
            </b>
            , a company incorporated under the Companies Act, 2013 with its
            registered office at Room No. 203, 2nd Floor, Front Side, 2-A/3,
            Kundan Mansion, Asaf Ali Road, Turkman Gate Delhi Central Delhi DL
            110002 IN ("<b>Growthfile Analytics Private Limited</b>", "
            <b>Growthfile</b>", "<b>We</b>", "<b>Us</b>", or "<b>Our</b>").
          </p>

          <h4 className="privacyHead">What does EQLFIN do?</h4>
          <p className="textPara">
            EQLFIN is a technology platform that connects companies to enable
            collection of B2B payments.
          </p>
          <h4 className="privacyHead">Who can use EQLFIN?</h4>
          <p className="textPara">
            Only business entities or Companies with a valid Goods And Services
            Tax Identification Number (GSTIN) issued by the Government of India,
            and their authorised users can use Our Platform. This includes sole
            proprietorship firms, companies and partnerships, which can form
            legally binding contracts under the Indian Contract Act of 1872.
            <br />
            <br />
            You hereby represent that You are a representative user of a
            registered business entity and have been granted the authority to
            access the Platform on its behalf, You also represent that: (a) You
            have a valid GSTIN issued by the Government of India; (b) You have
            been authorised through a contract with the registered Company to
            access the platform on its behalf (c) You have the authority to bind
            the entity to these Terms and/or this Agreement; and (d) all other
            information submitted to EQLFIN during the registration process is
            true, accurate, current and complete.
            <br />
            <br />
            Any registered business entity and its representative individual
            that meets these Eligibility Criteria is free to access and use the
            Platform.
          </p>
          <h4 className="privacyHead">Who is prohibited from using EQLFIN?</h4>
          <p className="textPara">
            Only Companies and Individuals meeting the eligibility criteria set
            out in the above Clause titled - '<b>Who can use EQLFIN?</b>' are
            permitted to access and use Our Platform.
            <br />
            <br />
            We do not permit usage by any other individual or entity and all
            such usage will be considered a violation of our terms of use and
            deemed "illegal". We reserve the right to pursue legal action
            against such use including to protect our copyright and proprietary
            rights. Any illegal use of the platform is a violation and we can
            use all data including individually identifiable data in the pursuit
            of legal remedy against violation of our terms and conditions. Such
            usage is not covered in this privacy policy.
            <br />
            <br />
            Persons who are "incompetent to contract" within the meaning of the
            Indian Contract Act of 1872 including minors, un-discharged
            insolvents etc. are also not eligible to use the Platform
          </p>
          <h4 className="privacyHead">Principles</h4>
          <p className="textPara">
            <ol className="privacyOl">
              <li>
                All Information shared by a Company and its representative users
                is fully owned by the Company itself, and its use is restricted
                to the business entity that owns it. This includes usage data
                and a log of actions performed on the platform by the Company's
                authorised users.
              </li>
              <br />
              <li>
                This Information is not shared with anyone other than a
                Company's authorized users, employees and partner agencies
                servicing company on behalf of EQLFIN
              </li>
              <br />
              <li>
                All Information shared by the Company is the exclusive property
                of the Company to store, retrieve and analyse as it deems fit
                basis an explicit agreement.
              </li>
              <br />
              <li>
                We are not responsible for the accuracy of Information shared
                with your Company by other Companies on the Platform.
              </li>
            </ol>
          </p>
          <h4 className="privacyHead">Revisions to Privacy Policy</h4>
          <p className="textPara">
            We reserve the right to update and revise this Privacy Policy at any
            time. Whether this Privacy Policy has been revised since Your last
            visit can be determined by You by referring to the "
            <b>Effective Date of the current Privacy Policy</b>" date at the top
            of this page. You are suggested to review this Privacy Policy
            regularly to ensure that You are aware of its terms and amendments.
            Your use of the Platform constitutes Your acceptance of the terms of
            the Privacy Policy as amended or revised by Us.
          </p>
          <h4 className="privacyHead">Information collected by Us</h4>
          <p className="textPara">
            All Information collected by us from a Company and it's authorised
            users is fully owned by the Company as stated in the above Clause
            titled '<b>Principles</b>'.
            <br />
            <br />
            Our primary goal in collecting Your Information is to enable the
            authorised users of of registered Companies to use Our Platform to
            its full capability and to provide a safe, efficient and smooth
            experience. This allows EQLFIN to provide Services and features that
            meet Your Company's needs.
            <br />
            <br />
            On Your use of the platform, the following information may be shared
            with us by You:
          </p>
          <br />
          <p className="textPara">
            <ol className="privacyOl">
              <li>
                <b>Personal Information:</b> Name, gender, date of birth,
                images, mobile number, email address, bank account information
                and content which You knowingly disclose andis collected on an
                individual basis.
              </li>
              <br />
              <li>
                <b>Automatic Information:</b> We receive and store certain
                information such as Your location, which automatically becomes
                accessible to Us by virtue of You accessing the Platform on Your
                device. Additionally, like many other Platforms, We use Cookies
                and obtain certain types of information when Your device
                accesses the Platform or advertisements and other content served
                by or on behalf of the Platform.
                <br />
                <br /> Cookie is a small data file that is sent to or accessed
                from Your device when You use the Platform ("
                <b>Cookies</b>"). The use of Cookies helps us to assist Your use
                of certain aspects of the Platform. You can delete Cookies at
                any time, or You can set Your device to reject or disable
                Cookies. We may use information from Cookies for the following
                purposes:
                <br />
                <br />
                <ol type="a" className="privacyOl">
                  <li>Traffic monitoring</li>
                  <li>IP address from which You access the Platform</li>
                  <li>
                    Type of device and operating system used to access the
                    Platform
                  </li>
                  <li>Date and time of Your access to the Platform</li>
                  <li>Pages that You visit.</li>
                </ol>
              </li>
              <br />
              <li>
                <b>Stored Information:</b> We store all data that is shared by
                You or by a third party on Your behalf such as image files,
                documents etc. either at the time of accessing the Platform or
                at a later stage.
              </li>
            </ol>
          </p>
          <br />
          <p className="textPara">
            Personal Information, Automatic Information and Stored Information
            shall individually be referred to as "<b>Personal Information</b>",
            "<b>Automatic Information</b>", "<b>Stored Information</b>" and
            collectively as "<b>Information</b>".
            <br />
            <br />
            Information does not refer to any content that You may share with Us
            for the purposes of publishing on Our Platform. Such content shall
            be governed by Our Terms of Use.
          </p>
          <h4 className="privacyHead">Use of Information</h4>
          <p className="textPara">
            We use Your Information
            <ol className="privacyOl">
              <br />
              <li>
                For understanding Users to provide better Services of the
                Platform.
              </li>
              <br />
              <li>
                To customise Your experience; detect and protect Us against
                error, fraud and other criminal activity, enforce Our terms and
                conditions, and as otherwise described to You at the time of
                collection.
              </li>
              <br />
              <li>
                To contact you via phone, SMS, email or third-party
                communication services for payment reminders, Platform-usage
                related information, security announcements; any updates or
                developments pertaining to the Platform or Your use of the
                Platform
              </li>
              <br />
              <li>
                To provide You with technical support, including Platform
                updates.
              </li>
              <br />
              <li>
                To facilitate payment processes and verify payment information
                (if applicable)
              </li>
            </ol>
          </p>
          <h4 className="privacyHead">
            Types of information not allowed on the Platform
          </h4>
          <ul className="privacyOl">
            <li>Nudity or other sexually suggestive content</li>
            <li>Hate speech</li>
            <li>
              Credible threats or direct attacks on an individual or group
            </li>
            <li>Content that contains self-harm or excessive violence</li>
            <li>Content that is deemed illegal by the governing laws</li>
            <li>Fake or impostor profiles</li>
            <li>Spam</li>
          </ul>

          <h4 className="privacyHead">Information Sharing Policy</h4>
          <ol className="privacyOl">
            <li>
              Generally, EQLFIN will not disclose personally identifiable
              information that is collected through Your use of the Platform to
              unaffiliated third parties. We reserve the right, however, to
              provide such information to Our affiliates, third party
              contractors, employees, contractors, agents, and designees to the
              extent necessary to enable them to perform certain Platform
              related Services (such as improving Platform related Services and
              features, or maintenance Services) on Our behalf.
            </li>
            <br />
            <li>
              We also reserve the right to disclose such information to any
              third party if We believe that We are required to do so for any or
              all of the following reasons: (i) by law; (ii) to comply with
              legal processes or governmental requests; (iii) to prevent,
              investigate, detect, or prosecute criminal offenses or attacks on
              the technical integrity of the Platform or Our network; and/or
              (iv) to protect the rights, property, or safety of or Our
              employees, the Users of the Platform, the public, or You.
            </li>
          </ol>

          <h4 className="privacyHead">
            Changes of business ownership and control
          </h4>
          <ol className="privacyOl">
            <li>
              EQLFIN may, from time to time, expand or reduce Our business and
              this may involve the sale and/or the transfer of control of all or
              part of Growthfile Analytics Private Limited. Information provided
              will, where it is relevant to any part of Our business so
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this Privacy
              Policy, be permitted to use the Information for the purposes for
              which it was originally given to Us.
            </li>
            <br />
            <li>
              We may also disclose Information to a prospective purchaser of Our
              business or any part of it.
            </li>
            <br />
            <li>
              In the above instances, we will take steps to ensure that Your
              privacy is protected.
            </li>
          </ol>

          <h4 className="privacyHead">Data retention</h4>
          <ol className="privacyOl">
            <li>
              Unless a longer retention period is required or permitted by law,
              we will only hold Your Information on Our systems for the period
              necessary to fulfil the purposes outlined in this Privacy Policy.
            </li>
            <br />
            <li>
              Even if EQLFIN deletes Your Information, it may persist on backup
              or archival media for legal, tax or regulatory purposes.
            </li>
          </ol>
          <h4 className="privacyHead">
            Technical and functional management of the Platform
          </h4>
          <p className="textPara">
            When You use Our Platform, We may also process technical data such
            as Your IP address, Device ID or MAC address, and information about
            the manufacturer, model, and operating system of Your mobile device.
            We use this data to enable us to deliver the functionalities of the
            Platform, resolve technical difficulties, and provide You with the
            correct (prevent the transmission of spam, viruses and/or unlawful,
            defamatory, obscene or otherwise undesirable material or content)
            and most up to date version of the Platform.
          </p>
          <h4 className="privacyHead">Security</h4>
          <p className="textPara">
            EQLFIN takes Your security seriously and takes precautions to keep
            Your Information secure. We have put in place appropriate physical,
            electronic and managerial procedures to safeguard the information
            that is collected. However, due to the open communication nature of
            the internet, We cannot guarantee that communications between You
            and EQLFIN, or information stored on Our Platform, will be free from
            unauthorized access by third parties. Any transmission of
            information by You to Our Platform is at Your own risk.
          </p>
          <h4 className="privacyHead">Legal Disclaimer</h4>
          <p className="textPara">
            We reserve the right to disclose Your personally identifiable
            information as required by law and when we believe that disclosure
            is necessary to protect Our rights and/or to comply with a judicial
            proceeding, court order or legal process served on Our Platform. We
            may be required to share the aforesaid information with government
            authorities and agencies for the purposes of verification of
            identity or for prevention, detection, and investigation including
            cyber incidents, prosecution and punishment of offences. You agree
            and consent for the Company to disclose Your Information if so
            required under the applicable law.
          </p>
          <h4 className="privacyHead">A Special Note about Children</h4>
          <p className="textPara">
            The Services under the Platform are not directed towards children
            under 18 (eighteen). They are not allowed to register with or use
            the service or provide personal data. If We discover that We have
            collected personal information from a child under 18, We will delete
            that information immediately.
          </p>
          <h4 className="privacyHead">Governing Law</h4>
          <p className="textPara">
            The laws of India shall govern this Privacy Policy, regardless of
            Your location. You hereby expressly consent to exclusive
            jurisdiction and venue located in Delhi, India, for all matters or
            disputes arising out of or relating to the Terms of Use or Your
            access or use of the Platform.
            <br />
            <br />
            ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE TERMS OF USE OR
            YOUR USE OF THE PLATFORM MUST BE BROUGHT BY YOU WITHIN ONE (1) MONTH
            OF THE DATE SUCH CAUSE OF ACTION FIRST AROSE, OR SUCH CAUSE OF
            ACTION IS FOREVER WAIVED BY YOU. EACH CAUSE OF ACTION SHALL BE
            ADJUDICATED INDIVIDUALLY, AND YOU AGREE NOT TO COMBINE YOUR CLAIM
            WITH THE CLAIM OF ANY THIRD PARTY.
          </p>

          <h4 className="privacyHead">Grievance Redressal</h4>
          <p className="textPara">
            If You find any discrepancies or have any grievances in relation to
            the collection, storage, use, disclosure and transfer of Your
            Personal Information under this Privacy Policy or other terms and
            conditions or policies of any of the Company's entities, please
            contact Us at{" "}
            <a href="mailto:help@eqlfin.com" style={{ textDecoration: "none" }}>
              help@eqlfin.com
            </a>
          </p>
        </div>
      </section>
      <br />
      <section id="terms">
        <div className="content-max-widthSecurity">
          <h1 className="privacy" style={{ marginTop: "20px" }}>
            Terms of Use
          </h1>
          <hr />
          <br />
          <p className="textPara">
            THIS DOCUMENT IS AN ELECTRONIC RECORD IN THE TERMS OF INFORMATION
            TECHNOLOGY ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE
            AMENDED PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS
            STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS
            ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT
            REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
            <br />
            <br />
            THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE
            3 (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES)
            RULES, 2011 THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS,
            PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF THE PLATFORM.
          </p>
          <p className="textPara">
            Welcome to the Terms of Use (<b>“Terms”</b>) of EQLFIN.{" "}
            <b>
              EQLFIN is wholly owned by Growthfile Analytics Private Limited
            </b>
            , a company incorporated under the Companies Act, 2013 with its
            registered office at Room No. 203, 2nd Floor, Front Side, 2-A/3,
            Kundan Mansion, Asaf Ali Road, Turkman Gate Delhi Central Delhi DL
            110002 IN (<b>“We”</b>,<b> ”Us”</b>, or <b>“Our”</b>). EQLFIN is the
            business of helping the businesses in automating their processes and
            payments (<b>“Service”</b>).
          </p>
          <p className="textPara">
            By accessing or using the website available at eqlfin.com and any
            subdomains (“<b>Website</b>”) and Our mobile application available
            on Android and iOS platforms (“<b>Application</b>”). You are deemed
            to be bound by these Terms.
          </p>
          <p className="textPara">
            The Website and Application are collectively referred to as “
            <b>Platform</b>”.
          </p>
          <p className="textPara">
            The use of any personal data You submit to Us is governed by our
            Privacy Policy (available here). You acknowledge that by using the
            Platform You have reviewed our Privacy Policy.
            <br />
            Your agreement with Us includes these Terms and the Privacy Policy
            available on the Platform (collectively “<b>Agreement</b>”).
          </p>
          <p className="textPara">
            <b>
              IF YOU DO NOT AGREE TO BE BOUND BY OR ABIDE BY THESE TERMS, YOU
              MAY NOT USE OR ACCESS THE PLATFORM.
            </b>
          </p>
          <p className="textPara">
            All terms used with a capitalized first letter shall have the
            meaning assigned to them in these Terms.
          </p>

          <ol>
            <li className="termsHead">
              <h4> DEFINITIONS</h4>
            </li>
            <p className="textPara">
              All terms used with a capitalized first letter shall have the
              meaning assigned to them hereunder.
            </p>
            <br />
            <ol className="termsInsideol">
              <li style={{ listStyleType: "none" }}>
                1.1 “<b>User</b>” or “<b>You</b>” or “<b>Your</b>” shall mean
                any person that represents a Vendor or a Company or any other
                business entity who has registered and can access the Platform.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.2.{" "}
                <b>
                  “Us” or “We” or “Our” or “EQLFIN ” means Growthfile Analytics
                  Private Limited.
                </b>
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.3. <b>“Company”</b> shall mean any entity, partnership, sole
                proprietorship or any other registered business entity that has
                a valid GSTIN issued by the Government of India.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.4. <b>“Services”</b> shall mean all services offered by the
                Platform
              </li>
            </ol>

            <li className="termsHead">
              <h4>OVERVIEW</h4>
            </li>
            <p className="textPara">
              We reserve the right to change, modify, amend, or update these
              Terms and/or the Agreement from time to time and such amended
              provisions of these Terms and/or the Agreement shall be effective
              immediately upon being posted on the Platform. You can determine
              when these Terms and/or Agreement were last revised by referring
              to the ’LAST UPDATED’ at the top of these Terms and/or Agreement.
              Your continued use of the Platform shall be deemed to signify Your
              acceptance of these amended provisions of these Terms and/or the
              Agreement.
            </p>

            <li className="termsHead">
              <h4>ELIGIBILITY CRITERIA</h4>
            </li>
            <p className="textPara">
              For this please see above Clause titled{" "}
              <b>“Who can use EQLFIN?”</b>
            </p>
            <li className="termsHead">
              <h4>GENERAL DISCLAIMER</h4>
            </li>
            <ol className="termsInsideol">
              <li style={{ listStyleType: "none" }}>
                4.1. By using the Platform, You agree that You meet the
                Eligibility Criteria as detailed in the Clause titled “
                <b>Who can use EQLFIN?</b>” and that You are fully able and
                competent to understand and accept this Agreement as a binding
                contract and to abide by these Terms.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.2. You agree that You shall not access the Platform by any
                other means other than through the Platform that is provided by
                Us.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.3. You agree that You shall not acquire, copy, or monitor any
                portion of the Platform in any way to reproduce or circumvent
                the navigational structure or presentation of the Platform, to
                obtain or attempt to obtain any materials, documents, or
                information through any means not specifically made available
                through the Platform.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.4. You agree that You shall not attempt to reverse engineer,
                de-encrypt, or otherwise derive the design, internal logic,
                structure or inner workings (including algorithms and source
                code) of the Platform, any other software, products, models,
                prototypes, or other items provided by Us.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.5. You agree that You shall not use any deep-link, robot,
                spider or other automatic device or methodology, or any similar
                or equivalent manual process, to access, acquire, download, copy
                or monitor the Platform or any part thereof, or obtain any
                content through means not specifically made available through
                the Platform.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.6. You agree that You shall not breach any provisions of the
                Agreement or violate any provisions of applicable law.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                4.7{" "}
                <b>
                  ALL STATUTORY COMPLIANCES AND OTHER GOVERNMENT DUES INCLUDING
                  THE DETERMINATION OF TDS AND GST RATES AND DEPOSIT OF THE
                  RELEVANT DUES WITH THE CONCERNED AUTHORITIES IS THE
                  RESPONSIBILITY OF THE COMPANY AND EQLFIN IS NOT RESPONSIBLE
                  FOR THE SAME IN ANY MANNER.
                </b>
              </li>
            </ol>

            <li className="termsHead"> CODE OF CONDUCT</li>
            <p className="textPara">
              You shall not host, display, upload, modify, publish, transmit,
              update or share any information that:
            </p>
            <br />
            <ol className="termsInsideol" style={{ paddingLeft: "16px" }}>
              <li>
                belongs to another person and to which the You do not have any
                right to
              </li>
              <br />
              <li>
                is grossly harmful, harassing, blasphemous defamatory, obscene,
                pornographic, paedophilic, libellous, invasive of another's
                privacy, hateful, or racially, ethnically objectionable,
                disparaging, relating or encouraging money laundering or
                gambling, or otherwise unlawful in any manner whatever
              </li>
              <br />
              <li> harms minors in any way</li>
              <br />
              <li>
                infringes any patent, trademark, copyright or other proprietary
                rights
              </li>
              <br />
              <li>violates any law for the time being in force</li>
              <br />
              <li>
                deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature
              </li>
              <br />
              <li>impersonates another person</li>
              <br />
              <li>
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource
              </li>
              <br />
              <li>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognisable
                offence or prevents investigation of any offence or is insulting
                any other nation.
              </li>
            </ol>

            <li className="termsHead">SCOPE OF SERVICE</li>
            <p className="textPara">
              EQLFIN helps Companies deliver services and collect payments. For
              this Companies will be charged periodically for specific services
              that are necessary for ensuring payment collection against
              invoices on their behalf. We are only technology solution for
              enabling the above.
            </p>
            <li className="termsHead">REGISTRATION PROCESS</li>
            <p className="textPara">
              To become a User and to access products and services on our
              Platform, You must first register on Our Platform. You will be
              prompted to provide certain information including without
              limitation,
            </p>
            <br />
            <ol className="termsInsideol" style={{ paddingLeft: "16px" }}>
              <li>Your Company’s GSTIN and address information</li>
              <li>The registered name of the Company</li>
              <li>
                A valid email address, mobile number and other contact
                information a valid email address, mobile number and other
                contact information
              </li>
            </ol>
            <br />
            <p className="textPara">
              The information You submit will be stored and used in accordance
              with our Privacy Policy. You hereby acknowledge that You have no
              ownership rights over Your account and that if you cancel Your
              account, all information uploaded to it will be marked for
              deletion and removed from the Platform.
            </p>
            <li className="termsHead">PAYMENT TERMS</li>

            <p className="textPara">
              A registered business entity has to purchase service packs on the
              EQLFIN website in order to avail of services on Our Platform.
              These may include subscription plans, pay per use packs and
              prepaid plans.
              <br />
              <br />
              All payments must necessarily and compulsorily be made in INR for
              all transactions.
            </p>
            <li className="termsHead">REPRESENTATIONS AND WARRANTIES</li>
            <p className="textPara">
              To become a User and to provide or access Services through our
              Platform, You must first register for the appropriate account
              through the registration portal provided on Our Platform. You can
              either register as a Company to avail Services from the Vendor or
              register as a Vendor to provide the Services. You will be prompted
              to provide certain information including without limitation,
            </p>
            <br />
            <ol className="termsInsideol">
              <li style={{ listStyleType: "none" }}>
                9.1 You represent and warrant that:
              </li>
              <ol>
                <li style={{ listStyleType: "none" }}>
                  9.1.1 You have the right and authority to enter into the Terms
                  and have been authorised to access the Platform;
                </li>
                <li style={{ listStyleType: "none" }}>
                  9.1.2 You will not use the Platform to defraud or mislead any
                  person or entity;
                </li>
                <li style={{ listStyleType: "none" }}>
                  9.1.3 That any and all information uploaded by You or your
                  representative users to the Platform is accurate and up to
                  date.
                </li>
              </ol>
            </ol>

            <li className="termsHead">INTELLECTUAL PROPERTY</li>
            <p className="textPara">
              By posting your information and/or content on our Platform, You
              hereby grant Us a worldwide, non-exclusive, perpetual,
              irrevocable, royalty-free, fully paid, sub-licensable and
              transferable license to use, modify, reproduce, distribute,
              display and publish your content/information on Our Platform for
              rendering the Services. We hereby reserve the right but not
              obligation to monitor Your content/information on our Platform. We
              also reserve the right to remove or disable any of Your
              content/information for any reason or for no reason at all.
            </p>
            <li className="termsHead">TERMINATION</li>
            <p className="textPara">
              EQLFIN may in its sole discretion and without any prior notice,
              suspend or terminate your access to the Platform and/or block
              future access to the Platform, if we determine that you have
              violated these Terms and/or any other terms of the Agreement. You
              acknowledge that any violation by you of these Terms may cause
              irreparable harm to Growthfile, for which monetary damages would
              be inadequate, and You consent to EQLFIN obtaining any injunctive
              or equitable relief that We feel would be necessary or appropriate
              in such circumstances, without limiting other available remedies.
              Termination for bad feedback to the Users
            </p>
            <li className="termsHead">USE OF YOUR PERSONAL INFORMATION</li>
            <ol className="termsInsideol">
              <li style={{ listStyleType: "none" }}>
                12.1 All Information shared by a Company and its representative
                users is fully owned by the Company itself, and its use is
                restricted to the business entity that owns it. This includes
                usage data and a log of actions performed on the platform by the
                Company’s authorised users.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                12.2 All Information shared by the Company is the exclusive
                property of the Company to store, retrieve and analyse as it
                deems fit.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                12.3 All individuals using the platform are using it on behalf
                of the Company that has given them access. Unauthorized use is
                strictly prohibited.
              </li>
            </ol>
            <li className="termsHead">WARRANTY DISCLAIMER</li>
            <p className="textPara">
              EQLFIN IS MERELY A FACILITATOR. THIS PLATFORM IS PROVIDED BY
              EQLFIN ON AN “AS IS” AND “AS AVAILABLE” BASIS. EQLFIN MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED AS
              TO THE OPERATION OF THIS PLATFORM OR THE INFORMATION, CONTENT
              INCLUDED ON THIS PLATFORM (INCLUDING THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, TITLE
              AND NON-INFRINGMENT, ARISING BY STATUTE OR OTHERWISE IN LAW OR
              FROM A COURSE OF DEALING OR USAGE OR TRADE). YOU EXPRESSLY AGREE
              THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. EQLFIN
              RESERVES THE RIGHT TO WITHDRAW OR DELETE ANY INFORMATION FROM THIS
              PLATFORM AT ANY TIME AT ITS DISCRETION.
            </p>
            <br />
            <p className="textPara">
              EQLFIN SHALL NOT AND IS NOT REQUIRED TO MEDIATE OR RESOLVE ANY
              DISPUTE OR DISAGREEMENT BETWEEN BUSINESS ENTITIES ON ITS PLATFORM.
            </p>
            <br />
            <p className="textPara">
              TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, EQLFIN DISCLAIMS
              ALL WARRANTIES, EXPRESS OR IMPLIED. EQLFIN DOES NOT WARRANT THAT
              THIS PLATFORM, ITS SERVERS, OR EMAIL SENT FROM EQLFIN ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>

            <li className="termsHead">LIMITATION OF LIABILITY</li>
            <p className="textPara">
              IN NO EVENT SHALL WE BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT,
              CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES PURSUANT TO THIS
              AGREEMENT, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, LOSS OF
              BUSINESS OPPORTUNITIES OR LOSS OF GOODWILL, EVEN IF ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANY OTHER PROVISION
              OF THIS AGREEMENT, IN NO EVENT WILL OUR LIABILITY TO YOU FOR ANY
              ACTION OR CLAIM RELATED TO THE PLATFORM PROVIDED UNDER THESE
              TERMS, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE OR ANY OTHER
              THEORY OF LIABILITY, EXCEED THE GREATER OF THE AGGREGATE AMOUNT
              COLLECTED BY US FROM YOU FOR THE USE OF THE PLATFORM TO WHICH THE
              LIABILITY RELATES DURING THE SIX (6) MONTH PERIOD IMMEDIATELY
              PRECEDING THE DETERMINATION OF SUCH LIABILITY.
            </p>
            <li className="termsHead">INDEMNIFICATION</li>
            <p className="textPara">
              You agree to indemnify, defend and hold harmless EQLFIN from and
              against any and all losses, liabilities, claims, damages, costs
              and expenses (including legal fees and disbursements in connection
              therewith and interest chargeable thereon) asserted against or
              incurred by EQLFIN that arise out of, result from, or may be
              payable by virtue of, any breach or non-performance of any
              representation, warranty, covenant or agreement made or obligation
              to be performed by You pursuant to these Terms or Privacy Policy.
            </p>
            <li className="termsHead">SEVERABILITY</li>
            <p className="textPara">
              If, for any reason, a court of competent jurisdiction finds any
              provision of the Terms, or portion thereof, to be unenforceable,
              that portion shall be enforced to the maximum extent permissible
              so as to give effect to the intent of the parties as reflected by
              that provision. The remainder of the Terms shall continue in full
              force and effect.
            </p>
            <li className="termsHead">INDEPENDENT RELATIONSHIP</li>
            <p className="textPara">
              The relationship between You and EQLFIN is that of an independent
              contractor. Nothing in this Agreement is intended to or should be
              construed to create an employment, agency, joint venture or
              partnership between You and Growthfile.
              <br />
              <br />
              Notice to User shall be deemed to be received by such User if and
              when the Platform is able to demonstrate that communication,
              whether in physical or electronic form, has been sent to such
              User, or immediately upon Platform’s posting such notice on an
              area of the Platform that is publicly accessible.
            </p>

            <li className="termsHead">WAIVER</li>
            <p className="textPara">
              No provision of the Agreement shall be deemed to be waived and no
              breach excused, unless such waiver or consent shall be in writing
              and signed by Growthfile Analytics Private Limited. Any consent by
              EQLFIN to, or a waiver by EQLFIN of any breach by You, whether
              expressed or implied, shall not constitute consent to, waiver of,
              or excuse for any other different or subsequent breach.
            </p>
            <li className="termsHead"> GOVERNING LAW AND JURISDICTION</li>
            <p className="textPara">
              These Terms shall be governed by the laws of India. Subject to the
              dispute resolution clause below, courts and tribunals of New
              Delhi, India have exclusive jurisdiction to settle any dispute
              arising out of or in connection with these Terms (including any
              disputes regarding the existence, validity or termination of these
              Terms).
            </p>

            <li className="termsHead">DISPUTE RESOLUTION</li>
            <p className="textPara">
              If any dispute, controversy or claim arises under, out of, or in
              relation to these Terms, including any dispute concerning the
              formation, construction, interpretation, or breach of these Terms
              or any party’s performance of its obligations under it, such
              dispute shall at the first instance be attempted to be resolved
              through mutual good faith consultations. If the dispute is not
              resolved in this manner within forty-five (45) days of either
              party sending a notice (in accordance with Clause 18) to the other
              party of such dispute, then the dispute shall be resolved by
              binding arbitration under the provisions of the Arbitration &
              Conciliation Act 1996, by a sole arbitrator in New Delhi, India.
              The sole arbitrator shall be appointed by Growthfile.
            </p>
            <li className="termsHead"> NOTICES</li>
            <p className="textPara">
              All notices or demands to or upon EQLFIN shall be effective if in
              writing and shall be deemed to be duly made when sent to Room No.
              203, 2nd Floor, Front Side, 2-A/3, Kundan Mansion, Asaf Ali Road,
              Turkman Gate Delhi Central Delhi DL 110002 IN All notices or
              demands to or upon a User shall be effective if either delivered
              personally, sent by courier, certified mail, by facsimile or email
              to the last-known correspondence, fax or email address provided by
              the You on the Platform or by posting such notice or demand on an
              area of the Platform that is publicly accessible.
              <br />
              <br />
              Notice to User shall be deemed to be received by such User if and
              when the Platform is able to demonstrate that communication,
              whether in physical or electronic form, has been sent to such
              User, or immediately upon Platform’s posting such notice on an
              area of the Platform that is publicly accessible.
            </p>
            <li className="termsHead">MISCELLANEOUS</li>
            <ol className="termsInsideol">
              <li style={{ listStyleType: "none" }}>
                1.1 &nbsp; Headings for any section of the Agreement are for
                reference purposes only and in no way define, limit, construe or
                describe the scope or extent of such section.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.2 &nbsp; EQLFIN shall have the right to assign its obligations
                and duties in this Agreement to any person or entity.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.3 &nbsp; All telephonic calls to EQLFIN will be kept
                completely confidential. However, Your call may be recorded to
                ensure the quality of service. Further, for training purposes
                and to ensure excellent customer service, calls from EQLFIN to
                You or from You to EQLFIN may be monitored and recorded.
              </li>
              <br />
              <li style={{ listStyleType: "none" }}>
                1.4 &nbsp; There shall be a designated Grievance Officer in
                respect of the Platform. Any complaints or concerns with regards
                to any content on the Platform or any breach of this Agreement
                or Privacy Policy can be directed to the designated Grievance
                Officer in writing an email signed with electronic signatures
                sent to
                <a href="mailto:help@eqlfin.com"> help@eqlfin.com</a>
              </li>
            </ol>
          </ol>
        </div>
      </section>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Footer />
      </div>
    </>
  );
}
