import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";

const HeaderLeft = () => {
  return (
    <Box
      style={{
        height: "100px",
        padding: "50px 72px",
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Link to="/" style={{ textAlign: "center" }}>
        <img src={logo} alt="logo" style={{ height: "100%", width: "85%" }} />
      </Link>
    </Box>
  );
};

export default HeaderLeft;
