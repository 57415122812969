import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTrackAuth } from "../../Contexts/TrackContext";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { trackAuth } from "../../firebaseSetupTrack";
import { Button, CircularProgress, TextField } from "@mui/material";
import styles from "../../css/supportPage.module.css";
import HeaderLeft from "../HeaderLeft";
import LeadSignupForm from "./LeadSignupForm";
import SecondForm from "./SecondForm";
import PageLoadingBox from "../PageLoadingBox";

const SupportPage = () => {
  const { state } = useLocation();
  const { token, currentUser } = useTrackAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentComponentState, setCurrentComponentState] = useState(
    state?.currentComponentState || "signupUser"
  );
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [appVerifer, setAppVerifier] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [resendButton, setResendButton] = useState(true);
  const [seconds, setSeconds] = useState(20);
  const [otpLoading, setOtpLoading] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [screen, setScreen] = useState("form");
  const [secondFormScreen, setSecondFormScreen] = useState("form");

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        setResendButton(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (token) {
      setLoginState(true);
    }
  }, [currentUser]);

  const phoneNumberRegex = /^[6-9][0-9]{9}$/;
  const handlePhoneChange = (e) => {
    if (e.target.value === "" || !phoneNumberRegex.test(e.target.value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhone(e.target.value);
  };

  const handleOtpChange = (e) => {
    if (e.target.value === "" || e.target.value.length !== 6) {
      setOtpError("Enter a valid OTP");
    } else {
      setOtpError("");
    }
    setOtp(e.target.value);
  };

  const resendButtonClick = async (e) => {
    e.preventDefault();
    setResendButton(false);

    await signInWithPhoneNumber(
      trackAuth,
      `+91${phone}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((err) => console.log("signin function error: ", err));
    setSeconds(20);
  };

  const otpTrigger = async (e) => {
    const phoneNumberRegex = /^[6-9][0-9]{9}$/;
    e.preventDefault();
    setResendButton(false);

    if (phoneNumberRegex.test(phone)) {
      setPhoneError(false);
      setOtpLoading(true);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-containers",
        {
          size: "invisible",
          "expired-callback": () => {},
        },
        trackAuth
      );

      window.recaptchaVerifier.render();
      setAppVerifier(window.recaptchaVerifier);
      await signInWithPhoneNumber(
        trackAuth,
        `+91${phone}`,
        window.recaptchaVerifier
      )
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setOtpLoading(false);
        })
        .catch((err) => {
          console.log("signin function error: ", err);

          window.recaptchaVerifier
            .render()
            .then((widgetId) => window.grecaptcha.reset(widgetId));
        });

      setCurrentComponentState("otpScreen");
    } else {
      if (phone === "" || !phoneNumberRegex.test(phone)) {
        setPhoneError(true);
      }
    }
    setLoading(false);
    setSeconds(20);
  };

  const otpVerify = async (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      setLoading(true);
      setOtpError(false);
      let confirmationResult = window.confirmationResult;
      let otpResult = "";
      try {
        otpResult = await confirmationResult.confirm(otp);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOtpError(true);
      }
      const otpVerificationResult = await otpResult;
      const token = otpVerificationResult._tokenResponse.idToken;
      if (otpVerificationResult) {
        // console.log("otp verified", token);
        setLoginState(true);
      } else {
        setOtpError(true);
        setLoginState(false);
      }
    } else {
      setOtpError(true);
    }
  };

  const toDisplay = (name) => {
    switch (name) {
      case "signupUser":
        return (
          <>
            <h2 className="legalName">Verify Phone Number</h2>

            <div className="gstDiv">
              <div className="gstInputDiv">
                <TextField
                  id="phoneNumber"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={handlePhoneChange}
                  label="Phone no."
                  variant="outlined"
                  className="gstinput"
                  value={phone}
                  error={phoneError}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      otpTrigger(e);
                    }
                  }}
                  helperText={phoneError && "Enter a valid phone number"}
                />
              </div>
              <>
                {phoneError ? (
                  <p style={{ color: "red", marginRight: "auto" }}>
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
              </>
            </div>
            <Button
              id="recaptcha-containers"
              variant="contained"
              onClick={otpTrigger}
              style={{
                borderRadius: "25px",
                margin: "2vh 0",
                height: "50px",
                width: "300px",
              }}
              className="otpTriggerButton"
            >
              {otpLoading ? "Please Wait..." : "Login"}
            </Button>

            <div id="recaptcha-containers"></div>
            <div id="recaptcha-containers-resend"></div>
          </>
        );

      case "otpScreen":
        return (
          <>
            <div className="gstDiv" style={{ marginBottom: "20px" }}>
              <h2 className={styles.subHeading}>Verify Phone Number</h2>
              <p>
                Enter the 6-digit code we sent{" "}
                <span
                  onClick={() => setCurrentComponentState("signupUser")}
                  style={{
                    color: "#1904ff",
                    cursor: "pointer",
                  }}
                >
                  +91-{phone}
                </span>
              </p>
            </div>
            <TextField
              id="otp"
              inputProps={{
                maxLength: 6,
              }}
              className={styles.inputField}
              onChange={handleOtpChange}
              label="OTP"
              variant="outlined"
              value={otp}
              error={otpError}
              helperText={otpError && "Enter a valid OTP"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  otpVerify(e);
                }
              }}
            />

            <div className={styles.buttonGroup}>
              <Button
                variant="text"
                style={{ color: "#1904ff" }}
                onClick={() => setCurrentComponentState("signupUser")}
              >
                Back
              </Button>
              <Button
                style={{
                  borderRadius: "25px",
                  width: "150px",
                  backgroundColor: "#1904ff",
                  color: "white",
                }}
                variant="contained"
                onClick={otpVerify}
              >
                Verify
              </Button>
            </div>

            {!resendButton ? (
              <div>
                Resend Code in 00:{seconds < 10 ? `0${seconds}` : seconds}{" "}
              </div>
            ) : (
              <Button onClick={resendButtonClick}>Resend</Button>
            )}

            <div
              style={{
                margin: "0px 5%",
                textAlign: "center",
                maxWidth: "400px",
              }}
            >
              By tapping Verify, you are indicating that you accept our{" "}
              <Link to="/security-privacy" target="_blank">
                <span style={{ color: "#1904ff" }}> Terms of Service </span>
              </Link>
              and
              <Link to="/security-privacy" target="_blank">
                <span style={{ color: "#1904ff" }}> Privacy Policy.</span>
              </Link>
            </div>
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <HeaderLeft />
      {loading ? (
        <PageLoadingBox />
      ) : (
        <div className={styles.container}>
          <h1 className={styles.pageHeading}>SupportPage</h1>
          {!loginState && (
            <>
              {loading ? (
                <>
                  <CircularProgress />
                  <h2 style={{ textAlign: "center" }}>
                    Please wait ... <br />
                  </h2>
                </>
              ) : (
                toDisplay(currentComponentState)
              )}
            </>
          )}
          <br />
          <br />
          {loginState && (
            <>
              <div className={styles.formContainer}>
                <LeadSignupForm screen={screen} setScreen={setScreen} />
                <SecondForm
                  screen={secondFormScreen}
                  setScreen={setSecondFormScreen}
                />
              </div>
            </>
          )}
          <div id="recaptcha-containers"></div>
          <div id="recaptcha-containers-resend"></div>
        </div>
      )}
    </>
  );
};

export default SupportPage;
