import { apiHeader, apiHeaderMs, url } from "../../config";
import moment from "moment";

export const pendingDemandRequest = async (token, officeId) => {
  let raw = undefined;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeader);

  let requestOptions = {
    method: "GET",
    body: raw,
    headers: myHeaders,
    redirect: "follow",
  };

  const result = await fetch(
    `${url}/office/${officeId}/pendingDemandsForVendor`,
    requestOptions
  );
  let res = await result.json();
  return res.demands;
};
export const pendingDemandRequestCustomer = async (token, officeId) => {
  let raw = undefined;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeader);
  let requestOptions = {
    method: "GET",
    body: raw,
    headers: myHeaders,
    redirect: "follow",
  };

  const result = await fetch(
    `${url}/office/${officeId}/pendingDemands`,
    requestOptions
  );
  let res = await result.json();
  return res.demands;
};
export const officeApiRequest = async (token, offices) => {
  var raw = undefined;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeader);
  var requestOptions = {
    method: "GET",
    body: raw,
    redirect: "follow",
    headers: {
      Authorization: `Bearer ${token}`,
      "Firebase-Env": apiHeader,
    },
  };
  const result = await fetch(`${url}/office`, requestOptions);
  let res = await result.json();
  return res;
};

export const officeCreateRequest = async (body, token) => {
  const requestOption = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
    redirect: "follow",
  };
  const response = await fetch(`${url}/services/office`, requestOption);
  const data = await response.json();
  return data;
};

export const customClaimDetails = async (user) => {
  const userCustomClaim = await user.getIdTokenResult();
  return userCustomClaim;
};

export const dayDiffer = (timestamp1, timestamp2) => {
  var difference = timestamp1 - timestamp2;
  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

  return daysDifference;
};
export const dueDateChangeDemand = async (body, token) => {
  const requestOption = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Firebase-Env": apiHeader,
    },
    body: JSON.stringify(body),
    redirect: "follow",
  };
  const response = await fetch(
    `${url}/invoice/payment_date/update`,
    requestOption
  );
  const data = await response.json();
  return data;
};

export const pendingDemandCalculation = (demands) => {
  let totalProcessingFee = 0;
  let availableAmount = 0;
  let earlyPaymentAmount = 0;

  const currentTimestamp = moment().add(1, "day").format("x");
  demands.forEach((demand) => {
    const dayDifference = dayDiffer(
      moment(demand.dueDate).endOf("day").format("x"),
      currentTimestamp
    );

    if (dayDifference > 0) {
      //
      const taxVal =
        demand.totalAmount -
        (demand.cgstAmount +
          demand.sgstAmount +
          demand.igstAmount +
          demand.tdsAmount);

      earlyPaymentAmount += taxVal;

      const processingFees = dayDifference * 50 * (taxVal / 100000);
      availableAmount += demand.pendingAmount;
      // demand.processingFees = processingFees;

      totalProcessingFee += processingFees;
    }
  });

  return {
    totalProcessingFee: totalProcessingFee,
    availableAmount: availableAmount,
    earlyPaymentAmount: earlyPaymentAmount,
  };
};

export const earlyPaymentRequest = async (token, officeId) => {
  let raw = undefined;

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeader);
  let requestOptions = {
    method: "GET",
    body: raw,
    headers: myHeaders,
    redirect: "follow",
  };

  const result = await fetch(
    `${url}/advanceDataRequest?officeId=${officeId}`,
    requestOptions
  );
  let res = await result.json();
  return res.message;
};

export const lastTwelveMonths = () => {
  const months = [];
  for (let i = 0; i <= 11; i++) {
    const currentMonthStartTimestamp = moment()
      .subtract(i, "month")
      .startOf("month")
      .format("x");

    months.push(currentMonthStartTimestamp);
  }
  return months;
};

export const invoiceAction = async (token, body) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Firebase-Env", apiHeader);

  const response = fetch(`${url}/invoice-action`, {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(body),
  });
  let res = await response.json();
  return res;
};

export const downloadStatementRequest = async (token, timestamp, officeId) => {
  let raw = undefined;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Firebase-Env", apiHeaderMs);
  let requestOptions = {
    method: "GET",
    body: raw,
    headers: myHeaders,
    redirect: "follow",
  };
  const result = await fetch(
    `${url}/api/advanceDataReport?officeId=${officeId}j&reqTimestamp=${timestamp}`,
    requestOptions
  );
  let res = await result.json();
  return res;
};
