import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import menu from "../../images/homepage/menu.svg";
import logo from "../../images/logo.svg";
import styles from "../../css/CommonComponent/HeaderWithProducts.module.css";
import dropdownicon from "../../images/commons/dropwdownicon.svg";

const HeaderWithoutProducts = () => {
  const ref = useRef();
  const dropdownref = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    const checkForDropdown = (e) => {
      if (
        dropDown &&
        dropdownref.current &&
        !dropdownref.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("mousedown", checkForDropdown);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("mousedown", checkForDropdown);
    };
  }, [menuOpen, dropdownref]);

  return (
    <section className={styles.headerContainer}>
      <Link to="/">
        <img loading="lazy" src={logo} alt="logo" style={{ width: "70%" }} />
      </Link>
      <span className={styles.linkSection}>
        <Link to="/about" className={styles.aboutText}>
          <span>ABOUT US</span>
        </Link>
      </span>
      <img
        loading="lazy"
        src={menu}
        className={styles.menuIcon}
        alt="menu icon"
        onClick={() => setMenuOpen(!menuOpen)}
      />
      {menuOpen && (
        <div className={styles.moreMenu} ref={ref}>
          <Link to="/about" className={styles.dropdownAboutText}>
            <span>ABOUT US</span>
          </Link>
        </div>
      )}
    </section>
  );
};

export default HeaderWithoutProducts;
