import { createTheme } from "@mui/material/styles";

import { blue, pink } from "@mui/material/colors";

export const appTheme = createTheme({
  palette: {
    // primary: pink,
    // secondary: blue,
    primary: {
      main: "#1904FF",
    },
    secondary: {
      main: "#B9B9B9",
    },
  },
});

// --mdc-theme-primary: #1904ff;
// --mdc-theme-secondary: #1e1e44;
// --mdc-theme-on-surface: #eaf2fd;
