import React from "react";
import HeaderWIthProfile from "../Common/HeaderWIthProfile";
import styles from "../../css/Earlypayment/Template.module.css";

const EarlyPaymentTemplate = ({
  showProfile = true,
  children,
  showHeader = false,
}) => {
  return (
    <div className={styles.wrapper}>
      {showHeader && <HeaderWIthProfile showProfile={showProfile} />}
      <div className={styles.headerContainer}>{children}</div>
    </div>
  );
};

export default EarlyPaymentTemplate;
