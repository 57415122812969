import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import menu from "../../images/homepage/menu.svg";
import logo from "../../images/logo.svg";
import styles from "../../css/CommonComponent/HeaderWithProducts.module.css";
import dropdownicon from "../../images/commons/dropwdownicon.svg";

const HeaderWithProducts = () => {
  const ref = useRef();
  const dropdownref = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector("#headerWithProducts");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    const checkForDropdown = (e) => {
      if (
        dropDown &&
        dropdownref.current &&
        !dropdownref.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("mousedown", checkForDropdown);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("mousedown", checkForDropdown);
    };
  }, [menuOpen, dropdownref]);

  return (
    <section className={styles.headerContainer} id="headerWithProducts">
      <Link to="/">
        <img loading="lazy" src={logo} alt="logo" style={{ width: "70%" }} />
      </Link>
      <span className={styles.linkSection}>
        <div
          className={styles.aboutText}
          onClick={() => setDropDown(!dropDown)}
          ref={dropdownref}
          onMouseOver={() => setDropDown(true)}
          onMouseOut={() => setDropDown(false)}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            Premium Services <img src={dropdownicon} alt="dropdownicon" />
          </span>
          {dropDown && (
            <div className={styles.dropdown}>
              <Link to="/services/priority-payments" className={styles.link}>
                Priority Payments
              </Link>

              <Link to="/services/reward-benefits" className={styles.link}>
                Reward Benefits
              </Link>
              <Link to="/services/personal-assistant" className={styles.link}>
                Personal Assistant
              </Link>
            </div>
          )}
        </div>
        <Link to="/services/balance-confirmation" className={styles.aboutText}>
          Balance Confirmation
        </Link>
        <a href="https://developer.eqlfin.com/" className={styles.aboutText}>
          Developer
        </a>
        <Link to="/about" className={styles.aboutText}>
          <span>About Us</span>
        </Link>
      </span>
      <img
        loading="lazy"
        src={menu}
        className={styles.menuIcon}
        alt="menu icon"
        onClick={() => setMenuOpen(!menuOpen)}
      />
      {menuOpen && (
        <div className={styles.moreMenu} ref={ref}>
          <div
            className={styles.dropdownAboutText}
            onClick={() => setDropDown(!dropDown)}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                zIndex: 200,
              }}
            >
              Premium Services <img src={dropdownicon} alt="dropdownicon" />
            </span>
            {dropDown && (
              <>
                <div className={styles.dropdown}>
                  <Link
                    to="/services/priority-payments"
                    className={styles.link}
                  >
                    Priority Payments
                  </Link>

                  <Link to="/services/reward-benefits" className={styles.link}>
                    Reward Benefits
                  </Link>
                  <Link
                    to="/services/personal-assistant"
                    className={styles.link}
                  >
                    Personal Assistant
                  </Link>
                </div>
              </>
            )}
          </div>
          <Link
            to="/services/balance-confirmation"
            className={styles.dropdownAboutText}
          >
            Balance Confirmation
          </Link>
          <a href="https://developer.eqlfin.com/" className={styles.link}>
            Developer
          </a>
          <Link to="/about" className={styles.dropdownAboutText}>
            <span>About Us</span>
          </Link>
        </div>
      )}
    </section>
  );
};

export default HeaderWithProducts;
